import reduxHelper from '../utils/redux';

export const reduxUtil = reduxHelper('QUESTION');

const { defineAction, createActionWithLoading, createAction } = reduxUtil;

export const actionTypes = {
    GET_QUESTION_LIST: defineAction('GET_QUESTION_LIST'),
    CREATE_QUESTION: defineAction('CREATE_QUESTION'),
    GET_QUESTION_BY_ID: defineAction('GET_QUESTION_BY_ID'),
    UPDATE_QUESTION: defineAction('UPDATE_QUESTION'),
    DELETE_QUESTION: defineAction('DELETE_QUESTION'),
    GET_COMBOBOX_COMPETENCES: defineAction('GET_COMBOBOX_COMPETENCES'),
}

export const actions = {
    getQuestionList: createActionWithLoading(actionTypes.GET_QUESTION_LIST),
    createQuestion: createAction(actionTypes.CREATE_QUESTION),
    getQuestionById: createAction(actionTypes.GET_QUESTION_BY_ID),
    updateQuestion: createAction(actionTypes.UPDATE_QUESTION),
    deleteQuestion: createActionWithLoading(actionTypes.DELETE_QUESTION),
    getComboboxCompetences: createAction(actionTypes.GET_COMBOBOX_COMPETENCES),
}