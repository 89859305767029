import {actionTypes, reduxUtil} from "../actions/competences";


const { createReducer, defineActionSuccess, defineActionLoading, defineActionFailed } = reduxUtil;

const {
    GET_COMPETENCES_LIST,
    DELETE_COMPETENCES,
    GET_SURVEYPROFILE_AUTOCOMPLETE_LIST,
    GET_WORKFIELD_AUTOCOMPLETE_LIST,
} = actionTypes;

const initialState = {
    competencesListData: {},
    competencesListLoading: false,
    surveyProfileListData: {},
    surveyProfileListLoading: false,
    workFieldListData: {},
    workFieldListLoading: false,
}

const reducer = createReducer ({
    [defineActionLoading(GET_COMPETENCES_LIST,)] : (state) =>{
        return {
            ...state,
            competencesListLoading: true,
        }
    },
    [defineActionSuccess(GET_COMPETENCES_LIST,)] : (state, competencesListData ) =>{
        return {
            ...state,
            competencesListData,
            competencesListLoading: false,
        }
    },
    [defineActionLoading(DELETE_COMPETENCES,)] : (state) =>{
        return {
            ...state,
            competencesListLoading: true,
        }
    },
    [defineActionFailed(DELETE_COMPETENCES,)] : (state) =>{
        return {
            ...state,
            competencesListLoading: false,
        }
    },

    [defineActionLoading(GET_SURVEYPROFILE_AUTOCOMPLETE_LIST)] : (state) =>{
        return {
            ...state,
            surveyProfileListLoading: true,
        }
    },
    [defineActionSuccess(GET_SURVEYPROFILE_AUTOCOMPLETE_LIST)] : (state, {surveyProfileListData} ) =>{
        return {
            ...state,
            surveyProfileListData,
            surveyProfileListLoading: false,
        }
    },
    [defineActionLoading(GET_WORKFIELD_AUTOCOMPLETE_LIST)] : (state) =>{
        return {
            ...state,
            workFieldListLoading: true,
        }
    },
    [defineActionSuccess(GET_WORKFIELD_AUTOCOMPLETE_LIST)] : (state, {workFieldListData} ) =>{
        return {
            ...state,
            workFieldListData,
            workFieldListLoading: false,
        }
    },
    initialState
})

export default {
    reducer
};