import { call, put, takeLatest } from 'redux-saga/effects';

import { sendRequest } from '../services/apiService';
import { actionTypes, reduxUtil } from '../actions/surveyProfile';
import apiConfig from '../constants/apiConfig';
import { handleApiResponse } from '../utils/apiHelper';

const { defineActionLoading, defineActionSuccess, defineActionFailed } = reduxUtil;

const {
    GET_SURVEY_PROFILE_LIST,
    DELETE_SURVEY_PROFILE,
} = actionTypes;


function* getSurveyProfileList({ payload: { params } }) {

    const apiParams = apiConfig.surveyProfile.getSurveyProfileList;
    const searchParams = { page: params.page, size: params.size };
    if(params.search) {
        if(params.search.surveyName) {
            searchParams.surveyName = params.search.surveyName
        }
        if(params.search.status) {
            searchParams.status = params.search.status
        }
    }
    try {
        const result = yield call(sendRequest, apiParams, searchParams);
        yield put({
            type: defineActionSuccess(GET_SURVEY_PROFILE_LIST),
            surveyProfileData: result.responseData && result.responseData.data
        });
    }
    catch(error) {
        yield put({ type: defineActionFailed(GET_SURVEY_PROFILE_LIST) });
    }
}

function* createSurveyProfile({payload: { params, onCompleted, onError }}){
    try {
        const apiParams = apiConfig.surveyProfile.createSurveyProfile;
        const result = yield call(sendRequest, apiParams, params);
        handleApiResponse(result, onCompleted, onError);
    }
    catch(error) {
        onError(error);
    }
}

function* getSurveyProfileById({ payload: { params, onCompleted, onError } }) {
    try {
        const apiParams = {
            ...apiConfig.surveyProfile.getSurveyProfileById,
            path: `${apiConfig.surveyProfile.getSurveyProfileById.path}/${params.id}`
        }
        const result = yield call(sendRequest, apiParams);
        handleApiResponse(result, onCompleted, onError);
    }
    catch(error) {
        onError(error);
    }
}

function* updateSurveyProfile({ payload: { params, onCompleted, onError } }) {
    try {
        const apiParams = apiConfig.surveyProfile.updateSurveyProfile;
        const result = yield call(sendRequest, apiParams, params);
        handleApiResponse(result, onCompleted, onError);
    }
    catch(error) {
        onError(error);
    }
}

function* deleteSurveyProfile({ payload: { params, onCompleted, onError } }) {
    try {
        const apiParams = {
            ...apiConfig.surveyProfile.deleteSurveyProfile,
            path: `${apiConfig.surveyProfile.deleteSurveyProfile.path}/${params.id}`
        }
        const result = yield call(sendRequest, apiParams);
        handleApiResponse(result, onCompleted, onError);

        const { success, responseData } = result;
        if(!success || !responseData.result)
            yield put({ type: defineActionFailed(DELETE_SURVEY_PROFILE) });
    }
    catch(error) {
        yield put({ type: defineActionFailed(DELETE_SURVEY_PROFILE) });
        onError(error);
    }
}

const sagas = [
    takeLatest(defineActionLoading(GET_SURVEY_PROFILE_LIST), getSurveyProfileList),
    takeLatest(actionTypes.CREATE_SURVEY_PROFILE, createSurveyProfile),
    takeLatest(actionTypes.GET_SURVEY_PROFILE_BY_ID, getSurveyProfileById),
    takeLatest(actionTypes.UPDATE_SURVEY_PROFILE, updateSurveyProfile),
    takeLatest(defineActionLoading(DELETE_SURVEY_PROFILE), deleteSurveyProfile),
]

export default sagas;