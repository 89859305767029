import React from "react";
import { Form, Col, Row } from "antd";

import BasicForm from "../common/entryForm/BasicForm";
import TextField from "../common/entryForm/TextField";
import DropdownField from "../common/entryForm/DropdownField";
import CropImageFiled from "../common/entryForm/CropImageFiled";
import { commonStatus } from "../../constants/masterData";
import {
  AppConstants,
  UploadFileTypes,
} from "../../constants";
import Utils from "../../utils";
import { showErrorMessage } from "../../services/notifyService";

class SurveyProfileForm extends BasicForm {
  constructor(props) {
    super(props);
    this.state = {
      avatar: props.dataDetail.surveyImage
        ? `${AppConstants.contentRootUrl}/${props.dataDetail.surveyImage}`
        : "",
      uploading: false,
    };
  }
  handleChangeAvatar = (info) => {
    console.log(info);
    if (info.file.status === "done") {
      Utils.getBase64(info.file.originFileObj, (avatar) =>
        this.setState({ avatar })
      );
    }
  };

uploadFileAvatar = (file, onSuccess) => {
    const { uploadFile } = this.props;
    this.setState({ uploading: true });
    uploadFile({
        params: { fileObjects: { file }, type: UploadFileTypes.AVATAR },
        onCompleted: (result) => {
        this.setFieldValue("surveyImage", result.data.filePath);
        this.setState({ uploading: false });
        onSuccess();
        },
        onError: (err) => {
        if (err && err.message) {
            showErrorMessage(err.message);
            this.setState({ uploading: false });
        }
        },
    });
};
  render() {
    const { isEditing, formId, loadingSave, dataDetail } = this.props;
    const { uploading, avatar } = this.state;
    return (
      <Form
        id={formId}
        ref={this.formRef}
        layout="vertical"
        onFinish={this.handleSubmit}
        initialValues={dataDetail}
      >
        <Row gutter={16}>
            <Col span={12}>
                <CropImageFiled
                    fieldName="surveyImage"
                    loading={uploading}
                    label="Hình"
                    imageUrl={avatar}
                    onChange={this.handleChangeAvatar}
                    uploadFile={this.uploadFileAvatar}
                    required
                    requiredMsg="Hãy đặt hình cho cấu hình nhóm"
                    disabled={loadingSave}
                />
                {!isEditing ? 
                <Col span={16}
                style={{paddingLeft: 0}}>
                <TextField
                  fieldName="surveyName"
                  min={6}
                  label="Tên"
                  required
                  disabled={loadingSave}
                />
                </Col> : null}
            </Col>
            <Col span={12}>
                <TextField
                    fieldName="surveyDescription"
                    label="Mô tả"
                    type="textarea"
                    style={{height: isEditing ? "150px" : "200px"}}
                    disabled={loadingSave}
                />
            </Col>
        </Row>
        
            {
              isEditing ? <Row gutter={16}>
              <Col span={12}>
                <TextField
                  fieldName="surveyName"
                  min={6}
                  label="Tên"
                  disabled={loadingSave}
                />
                </Col>
                <Col span={12}>
                <DropdownField
                fieldName="status"
                label="Trạng thái"
                required
                options={commonStatus}
                disabled={loadingSave}
                />
              </Col>
              </Row>
          : null
            }
      </Form>
    );
  }
}

export default SurveyProfileForm;
