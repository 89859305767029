import React, { Component } from 'react';

import { Form, Row, Col } from 'antd';
import TextField from "../../common/entryForm/TextField";

export default class BrowserFillAnswer extends Component {
    render() {
        const { fieldName, disabled } = this.props;
        return (
            <Row gutter={16}>
                <Col span={24}>
                            <Col span={24}>
                                <TextField
                                    fieldName={[fieldName, 0 , "url"]}
                                    label="Đường dẫn website"
                                    disabled={disabled}
                                    required
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập đường dẫn'}
                                    ]}
                                />
                            </Col>
                            
                            <Col span={24}>
                                <TextField
                                    fieldName={[fieldName, 0 ,"answer"]}
                                    label="Câu trả lời"
                                    disabled={disabled}
                                    type="textarea"
                                    required
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập câu trả lời'}
                                    ]}
                                />
                            </Col>
                </Col>
            </Row>
        )
    }
}
