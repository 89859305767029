import {actionTypes, reduxUtil} from "../actions/area";


const { createReducer, defineActionSuccess, defineActionLoading, defineActionFailed } = reduxUtil;

const {
    GET_AREA_LIST,
    DELETE_AREA,
} = actionTypes;

const initialState = {
    areaListData: {},
    areaListLoading: false,
}

const reducer = createReducer ({
    [defineActionLoading(GET_AREA_LIST,)] : (state) =>{
        return {
            ...state,
            areaListLoading: true,
        }
    },
    [defineActionSuccess(GET_AREA_LIST,)] : (state, areaListData ) =>{
        return {
            ...state,
            areaListData,
            areaListLoading: false,
        }
    },
    [defineActionLoading(DELETE_AREA,)] : (state) =>{
        return {
            ...state,
            areaListLoading: true,
        }
    },
    [defineActionFailed(DELETE_AREA,)] : (state) =>{
        return {
            ...state,
            areaListLoading: false,
        }
    },
    initialState
})

export default {
    reducer
};