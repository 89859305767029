import React, { Component } from "react";
import MergedRowTable from "./MergedRowTable";
import { DEFAULT_TABLE_ITEM_SIZE } from '../../constants';

class ExamDetailForm extends Component {
  initialSearch() {
    return {  };
  }

  constructor(props) {
    super(props);
    this.state={
      listLoading: true,
    }
    this.pagination = { pageSize: DEFAULT_TABLE_ITEM_SIZE };
    this.search = this.initialSearch();
  }

  componentDidMount(){
    this.props.getDataList({})
  }
  
  loadDataTable = () => {
    this.pagination.current = 1;
    this.pagination.pageSize = DEFAULT_TABLE_ITEM_SIZE;
    this.getList();
  }
  
  getList = () => {
    const { getDataList } = this.props;
    const page = this.pagination.current ? this.pagination.current - 1 : 0;
    const params = { page, size: this.pagination.pageSize, search: this.search};
    getDataList({ params });
  }

  renderIdColumn = () => {
    return {
      title: 'ID',
      dataIndex: 'id',
      width: '50px',
      align: 'center'
    }
  }
    

  render() {
    const {
      dataList,
      loading,
    } = this.props;
    this.pagination.total = dataList.totalElements || 0;
    const examDetailList = dataList.data || [];
    const arrayvalue = [].concat(examDetailList)
    .sort((a, b) => a.competence.competenceName >= b.competence.competenceName? 1 : -1)
    arrayvalue.map(a=>a.index = (arrayvalue.indexOf(a)))
    console.log("arrayvalue", arrayvalue)
    return (
      <div>
        <MergedRowTable
          loading={loading}
          rowKey={(record) => record.id}
          data={arrayvalue}
          pagination={false}
        />
      </div> 
    );
  }
}

export default ExamDetailForm;
