import React from "react";
import { Form, Col, Row } from "antd";

import BasicForm from "../common/entryForm/BasicForm";
import TextField from "../common/entryForm/TextField";
import DropdownField from "../common/entryForm/DropdownField";

import { commonStatus,} from "../../constants/masterData";

import {
  STATUS_ACTIVE,
} from "../../constants";

class CategoryForm extends BasicForm {
  constructor(props) {
    super(props);
    this.state = {
      disable: false
    };
  }

  getInitialFormValues = () => {
    const { isEditing, dataDetail } = this.props;
    

    if (!isEditing) {
      return {
        status: STATUS_ACTIVE,
        ordering: 0,
      };
    }
    return dataDetail;
  };

  render() {
    const { isEditing, formId, loadingSave } = this.props;
    return (
      <Form
        id={formId}
        ref={this.formRef}
        layout="vertical"
        onFinish={this.handleSubmit}
        initialValues={this.getInitialFormValues()}
      >
        <Row gutter={16}>
          <Col span={12}>
            <TextField
              fieldName="categoryName"
              min={6}
              label="Tên"
              required={!isEditing}
              disabled={loadingSave}
            />
          </Col>
          <Col span={12}>
            <TextField fieldName="description"
            label="Mô tả" 
            type="textarea"
            style={{height: '120px'}}
            required
            disabled={loadingSave}
          />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12} hidden>
            <DropdownField
              fieldName="status"
              label="Trạng thái"
              required
              options={commonStatus}
              disabled={loadingSave}
            />
          </Col>
          
          <Col span={12} hidden>
            <TextField
              fieldName="parentId"
              label="Parent ID"
              disabled
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default CategoryForm;
