import { call, put, takeLatest } from "redux-saga/effects";
import {actionTypes, reduxUtil } from "../actions/area";

import apiConfig from "../constants/apiConfig";

import { sendRequest } from "../services/apiService";
import { handleApiResponse } from "../utils/apiHelper";
const {defineActionLoading, defineActionSuccess, defineActionFailed} = reduxUtil;

const {
    GET_AREA_LIST,
    DELETE_AREA,
} = actionTypes;

function* getAreaList({ payload: {params} }){
    const apiParams = apiConfig.area.getAreaList;
    const searchParams = { page: params.page, size: params.size };

    if (params.search)
    {
        if (params.search.title)
            searchParams.title = params.search.title;
        if(params.search.status)
            searchParams.status = params.search.status;

    }

    try {
        const result = yield call (sendRequest, apiParams, searchParams);
        yield put ({
            type:defineActionSuccess(GET_AREA_LIST),
            areaListData: result.responseData && {
                ...result.responseData.data,
            },
        })
    }
    catch(error) {
        yield put({ type: defineActionFailed(GET_AREA_LIST) });
    }
}

function* getArea ({payload:{params, onCompleted, onError}})
{
    try {
        //Define which Api and its path
        const apiParams = {
            ...apiConfig.area.getById,
            path: `${apiConfig.area.getById.path}/${params.id}`
        }
        const result = yield call(sendRequest, apiParams);
        handleApiResponse(result,onCompleted,onError);
    }
    catch(error)
    {
        onError(error);
    }
}

function* createArea({payload: {params, onCompleted, onError}})
{
    try{
        const result = yield call (sendRequest, apiConfig.area.create, params);
        handleApiResponse(result, onCompleted, onError);
    }
    catch(error)
    {
        onError(error);
    }
}

function* updateArea({payload: {params, onCompleted, onError}})
{
    try{
        const result = yield call (sendRequest, apiConfig.area.update, params);
        handleApiResponse(result, onCompleted, onError);
    }
    catch(error)
    {
        onError(error);
    }
}

function* deleteArea({payload: {params, onCompleted, onError}})
{
    try{
        const apiParams = {
            ...apiConfig.area.delete,
            path: `${apiConfig.area.delete.path}/${params.id}`
        }
        const result = yield call (sendRequest, apiParams);
        handleApiResponse(result, onCompleted, onError);

        const { success, responseData } = result;
        if(!success || !responseData.result)
            yield put({ type: defineActionFailed(DELETE_AREA) });
    }
    catch(error)
    {
        yield put({ type: defineActionFailed(DELETE_AREA) });
        onError(error);
    }
}

const sagas = [
    takeLatest(defineActionLoading(GET_AREA_LIST), getAreaList),
    takeLatest(actionTypes.GET_AREA, getArea),
    takeLatest(actionTypes.CREATE_AREA, createArea),
    takeLatest(actionTypes.UPDATE_AREA, updateArea),
    takeLatest(defineActionLoading(DELETE_AREA), deleteArea),
]

export default sagas;