import { actionTypes, reduxUtil } from '../actions/surveyProfile';

const { createReducer, defineActionSuccess, defineActionLoading, defineActionFailed } = reduxUtil;
const {
    GET_SURVEY_PROFILE_LIST,
    DELETE_SURVEY_PROFILE,
} = actionTypes;

const initialState = { 
    surveyProfileData: [],
    tbSurveyProfileLoading: false,
};

const reducer = createReducer({
    [defineActionLoading(GET_SURVEY_PROFILE_LIST)]: (state) => {
        return {
            ...state,
            tbSurveyProfileLoading: true
        }
    },
    [defineActionSuccess(GET_SURVEY_PROFILE_LIST)]: (state, { surveyProfileData }) => {
        return {
            ...state,
            surveyProfileData,
            tbSurveyProfileLoading: false
        }
    },
    [defineActionLoading(DELETE_SURVEY_PROFILE)] : (state) =>{
        return {
            ...state,
            tbSurveyProfileLoading: true,
        }
    },
    [defineActionFailed(DELETE_SURVEY_PROFILE)] : (state) =>{
        return {
            ...state,
            tbSurveyProfileLoading: false,
        }
    },
    initialState
})

export default {
    reducer
};
