import React from "react";
import { connect } from "react-redux";
import { Button, Avatar } from "antd";
import { PlusOutlined, FileTextOutlined } from "@ant-design/icons";

import ListBasePage from "../ListBasePage";
import SurveyProfileForm from "../../compoments/surveyProfile/SurveyProfileForm";
import BaseTable from "../../compoments/common/table/BaseTable";
import BasicModal from "../../compoments/common/modal/BasicModal";
import { FieldTypes } from "../../constants/formConfig";
import { commonStatus } from "../../constants/masterData";

import { actions } from "../../actions";
import {
    AppConstants,
  } from "../../constants";

  import qs from 'query-string';
import { sitePathConfig } from "../../constants/sitePathConfig";
  
class SurveyProfileListPage extends ListBasePage {
  initialSearch() {
    return { surveyName: "", status: undefined, };
  }

  constructor(props) {
    super(props);
    this.objectName = "Cấu hình nhóm";
    this.breadcrumbs = [{ name: "Cấu hình nhóm" }];
    this.columns = [
      this.renderIdColumn(),
      {
        title: "Hình",
        dataIndex: "surveyImage",
        align: "center",
        width: 110,
        render: (avatar) => (
          <Avatar
            style={{width: "100px", height: "100px", borderRadius: "0", padding: "8px"}}
            size="large"
            icon={<FileTextOutlined />}
            src={avatar ? `${AppConstants.contentRootUrl}${avatar}` : null}
          />
        ),
      },
      {
        title: 'Tên', 
        render: (dataRow) => {
            return (
                <span style={{cursor: 'pointer', color: "#1890ff"}} onClick={()=>{
                    this.renderRouting(dataRow.id,dataRow.surveyName);
                }}>
                    {dataRow.surveyName}
                </span>
            )
        }
      },
      this.renderStatusColumn(),
      this.renderActionColumn(),
    ];
    this.actionColumns = {
      isEdit: true,
      isDelete: true,
      isChangeStatus: false,
    };

  }

  getSearchFields() {
    return [
      {
        key: "surveyName",
        seachPlaceholder: "Tên",
        initialValue: this.search.surveyName,
      },
      {
        key: "status",
        seachPlaceholder: "Chọn trạng thái",
        fieldType: FieldTypes.SELECT,
        options: commonStatus,
        initialValue: this.search.status,
      },
    ];
  }

renderRouting (id,name){
    let temp = qs.parse(this.props.location.search);
    let finalsearch={}
    if (temp.searchcompetencesName) finalsearch={...finalsearch, competencesName: temp.searchcompetencesName}
    if (temp.searchcompetencesstatus) finalsearch={...finalsearch, status: temp.searchcompetencesstatus}
    
    if (temp.searchquestName) finalsearch={...finalsearch, searchquestName: temp.searchquestName}
    if (temp.searchqueststatus) finalsearch={...finalsearch, searchqueststatus: temp.searchqueststatus}

    if (temp.searchworkFieldId) finalsearch={...finalsearch, workFieldId: temp.searchworkFieldId}
    else finalsearch={...finalsearch, workFieldId: 1036}

    if (temp.surveyName) finalsearch={...finalsearch, searchsurveyName: temp.surveyName, surveyProfileId: id}
    if (temp.status) finalsearch={...finalsearch, searchsurveystatus: temp.status}
    finalsearch={...finalsearch, SurveyName: name}
    finalsearch={...finalsearch, surveyProfileId: id}

     this.props.history.push(`${sitePathConfig.competences.path}?${qs.stringify(finalsearch)}`);

}

  render() {
    const {
      dataList,
      loading,
      uploadFile,
    } = this.props;
    const { isShowModifiedModal, isShowModifiedLoading } = this.state;
    const surveyProfile = dataList.data || [];
    this.pagination.total = dataList.totalElements || 0;

    return (
      <div>
        {this.renderSearchForm()}
        <div className="action-bar">
          {
            this.renderCreateNewButton((
              <Button
              type="primary"
              onClick={() => this.onShowModifiedModal(false)}
            >
              <PlusOutlined /> Cấu hình nhóm mới
            </Button>
            ))
          }
        </div>
        <BaseTable
          loading={loading}
          columns={this.columns}
          rowKey={(record) => record.id}
          dataSource={surveyProfile}
          pagination={this.pagination}
          onChange={this.handleTableChange}
        />
        <BasicModal
          visible={isShowModifiedModal}
          isEditing={this.isEditing}
          objectName={this.objectName}
          loading={isShowModifiedLoading}
          onOk={this.onOkModal}
          onCancel={this.onCancelModal}
        >
          <SurveyProfileForm
            isEditing={this.isEditing}
            dataDetail={this.isEditing ? this.dataDetail : {}}
            loadingSave={isShowModifiedLoading}
            uploadFile={uploadFile}
          />
        </BasicModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.surveyProfile.tbSurveyProfileLoading,
  dataList: state.surveyProfile.surveyProfileData || {},
});

const mapDispatchToProps = (dispatch) => ({
  getDataList: (payload) => dispatch(actions.getSurveyProfileList(payload)),
  getDataById: (payload) => dispatch(actions.getSurveyProfileById(payload)),
  createData: (payload) => dispatch(actions.createSurveyProfile(payload)),
  updateData: (payload) => dispatch(actions.updateSurveyProfile(payload)),
  deleteData: (payload) => dispatch(actions.deleteSurveyProfile(payload)),
  uploadFile: (payload) => dispatch(actions.uploadFile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyProfileListPage);
