import React from "react";
import { Form, Col, Row } from "antd";

import BasicForm from "../common/entryForm/BasicForm";
import TextField from "../common/entryForm/TextField";

import { commonStatus,} from "../../constants/masterData";
import DropdownField from "../common/entryForm/DropdownField";

import CropImageFiled from "../common/entryForm/CropImageFiled";

import { showErrorMessage } from "../../services/notifyService";

import {
    AppConstants,
    UploadFileTypes,
    STATUS_ACTIVE,
  } from "../../constants";
  import Utils from "../../utils";

class AreaForm extends BasicForm {
  constructor(props) {
    super(props);
    this.state = {
        avatar: props.dataDetail.workImage
          ? `${AppConstants.contentRootUrl}/${props.dataDetail.workImage}`
          : "",
        uploading: false,
      };
  }

  getInitialFormValues = () => {
    const { isEditing, dataDetail } = this.props;
    

    if (!isEditing) {
      return {
        status: STATUS_ACTIVE,
      };
    }
    // else {
    //   name: dataDetail.workTitle,
    //   this.setFieldValue("name",dataDetail.workTitle)
    // }
    return dataDetail;
  };
  handleChangeAvatar = (info) => {
    console.log(info);
    if (info.file.status === "done") {
      Utils.getBase64(info.file.originFileObj, (avatar) =>
        this.setState({ avatar })
      );
    }
  };

uploadFileAvatar = (file, onSuccess) => {
    const { uploadFile } = this.props;
    this.setState({ uploading: true });
    uploadFile({
        params: { fileObjects: { file }, type: UploadFileTypes.AVATAR },
        onCompleted: (result) => {
        this.setFieldValue("workImage", result.data.filePath);
        this.setState({ uploading: false });
        onSuccess();
        },
        onError: (err) => {
        if (err && err.message) {
            showErrorMessage(err.message);
            this.setState({ uploading: false });
        }
        },
    });
};
  render() {
    const { isEditing, formId, } = this.props;
    const { uploading, avatar } = this.state;

    return (
      <Form
        id={formId}
        ref={this.formRef}
        layout="vertical"
        onFinish={this.handleSubmit}
        initialValues={this.getInitialFormValues()}
      >
        <Row gutter={16}>
          <Col span={12}>
            <TextField
              fieldName="name"
              min={6}
              label="Tên"
              required={!isEditing}
            />
          </Col>
          <Col span={12}>
            <TextField fieldName="workDescriptionBasic"
            label="Mô tả cấp độ cơ bản" 
            type="textarea"
            style={{height: '120px'}}
            required
          />
          </Col>
        </Row>
        
        <Row gutter={16}>
        <Col span={12}>
            <TextField fieldName="workDescriptionMedium"
            label="Mô tả cấp độ trung bình" 
            type="textarea"
            style={{height: '120px'}}
            required
          />
          </Col>
          <Col span={12}>
            <TextField fieldName="workDescriptionAdvanced"
            label="Mô tả cấp độ nâng cao" 
            type="textarea"
            style={{height: '120px'}}
            required
          />
          </Col>
        </Row>

        <Row gutter={16}>
            <Col span={12}>
            <CropImageFiled
                fieldName="workImage"
                label="Ảnh đại diện"
                loading={uploading}
                imageUrl={avatar}
                onChange={this.handleChangeAvatar}
                uploadFile={this.uploadFileAvatar}
                // requiredMsg="Hãy nhập ảnh đại diện"
                required
                />
            </Col>
            
          <Col span={12}>
            <DropdownField
              fieldName="status"
              label="Trạng thái"
              required
              options={commonStatus}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default AreaForm;
