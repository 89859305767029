import apiConfig from './apiConfig';

export const sitePathConfig = {
    login: {
        path: '/login'
    },
    profile: {
        path: '/profile'
    },
    admin: {
        path: '/admins',
        permissions: [
            apiConfig.user.getAdminList.path,
            apiConfig.user.getAdminById.path,
            apiConfig.user.createAdmin.path,
            apiConfig.user.updateAdmin.path,
            apiConfig.user.deleteAdmin.path
        ]
    },
   
    forbidden: {
        path: '/forbidden'
    },
    setting: {
        path: '/settings',
        permissions: [
            apiConfig.setting.getSettingsList.path,
            apiConfig.setting.getSettingById.path,
            apiConfig.setting.createSetting.path,
            apiConfig.setting.updateSetting.path,
            apiConfig.setting.deleteSetting.path,
        ]
    },
    groupPermission: {
        path: '/groupPermission',
        permissions: [
            apiConfig.groupPermission.getList.path,
            apiConfig.groupPermission.getById.path,
            apiConfig.groupPermission.create.path,
            apiConfig.groupPermission.update.path,
            'not_have_delete',
            apiConfig.groupPermission.getPermissionList.path,
        ]
    },
    province: {
        path: '/province',
        permissions: [
            apiConfig.province.getProvinceList.path,
            apiConfig.province.getProvinceById.path,
            apiConfig.province.createProvince.path,
            apiConfig.province.updateProvince.path,
            apiConfig.province.deleteProvince.path,
        ]
    },
    category: {
        path: '/category',
        permissions: [
            apiConfig.category.getCategoryList.path,
            apiConfig.category.getById.path,
            apiConfig.category.create.path,
            apiConfig.category.update.path,
            apiConfig.category.delete.path,
        ]
    },
    categoryuniversity: {
        path: '/university',
        permissions: [
            apiConfig.category.getCategoryList.path,
            apiConfig.category.getById.path,
            apiConfig.category.create.path,
            apiConfig.category.update.path,
            apiConfig.category.delete.path,
        ]
    },
    categoryjob: {
        path: '/job',
        permissions: [
            apiConfig.category.getCategoryList.path,
            apiConfig.category.getById.path,
            apiConfig.category.create.path,
            apiConfig.category.update.path,
            apiConfig.category.delete.path,
        ]
    },
    news:{
        path: '/news',
        permissions: [
            apiConfig.news.getNewsList.path,
            apiConfig.news.getNewsById.path,
            apiConfig.news.createNews.path,
            apiConfig.news.updateNews.path,
            apiConfig.news.deleteNews.path,
            apiConfig.news.getComboboxNewsList.path.substring(0, apiConfig.news.getComboboxNewsList.path.length - 2),
        ]
    },
    customer:{
        path: '/customer',
        permissions: [
            apiConfig.customer.getCustomerList.path,
            apiConfig.customer.getCustomerById.path,
            'no_have_create',
            apiConfig.customer.updateCustomer.path,
            apiConfig.customer.deleteCustomer.path,
        ]
    },
    surveyProfile: {
        path: '/survey_profile',
        permissions:[
            apiConfig.surveyProfile.getSurveyProfileList.path,
            apiConfig.surveyProfile.getSurveyProfileById.path,
            apiConfig.surveyProfile.createSurveyProfile.path,
            apiConfig.surveyProfile.updateSurveyProfile.path,
            apiConfig.surveyProfile.deleteSurveyProfile.path,
            apiConfig.surveyProfile.getListAutoComplete.path,
        ],
    },
    area: {
        path: '/work_field',
        permissions:[
            apiConfig.area.getAreaList.path,
            apiConfig.area.getById.path,
            apiConfig.area.create.path,
            apiConfig.area.update.path,
            apiConfig.area.delete.path,
            apiConfig.area.getListAutoComplete.path,
        ],
    },
    question: {
        path: '/question',
        permissions:[
            apiConfig.question.getQuestionList.path,
            apiConfig.question.getById.path,
            apiConfig.question.create.path,
            apiConfig.question.update.path,
            apiConfig.question.delete.path,
            apiConfig.question.getComboboxCompetences.path,
        ],
    },
    competences: {
        path: '/competences',
        permissions:[ 
            apiConfig.competences.getCompetencesList.path,
            apiConfig.competences.getById.path,
            apiConfig.competences.create.path,
            apiConfig.competences.update.path,
            apiConfig.competences.delete.path,
        ],
    },
    exam: {
        path: '/exam',
        permissions: [
            apiConfig.exam.getListAdminList.path,
            'no_have_create',
            'no_have_create',
            'no_have_create',
            'no_have_create',
            apiConfig.exam.getDetailsAdminList.path,
        ]
    },
    examByCustomer: {
        path: '/examByCustomer',
        permissions: [
            apiConfig.exam.getListAdminList.path,
            'no_have_create',
            'no_have_create',
            'no_have_create',
            'no_have_create',
            apiConfig.exam.getDetailsAdminList.path,
        ]
    }
}