import React from "react";
import { connect } from "react-redux";
import { Button, Avatar, Tag } from "antd";
import { PlusOutlined, QuestionOutlined } from "@ant-design/icons";
import qs from 'query-string';

import ListBasePage from "../ListBasePage";
import QuestionForm from "../../compoments/question/QuestionForm";
import BaseTable from "../../compoments/common/table/BaseTable";
import BasicModal from "../../compoments/common/modal/BasicModal";
import { FieldTypes } from "../../constants/formConfig";
import { commonStatus, QuestionRank } from "../../constants/masterData";

import { actions } from "../../actions";
import {
    AppConstants,
  } from "../../constants";

class QuestionListPage extends ListBasePage {
  initialSearch() {
    let currentsearch = qs.parse(this.props.location.search);
    if (currentsearch.competencesId)
      return { questName: "", status: undefined, competencesId: currentsearch.competencesId};
    return { questName: "", status: undefined};
  }

  constructor(props) {
    super(props);
    this.objectName = "câu hỏi";
    this.breadcrumbs = [{ name: "Câu hỏi" }];
    this.columns = [
      this.renderIdColumn(),
      {
        title: "Hình",
        dataIndex: "questionAvatar",
        align: "center",
        width: 110,
        render: (avatar) => (
          <Avatar
            style={{width: "100px", height: "100px", borderRadius: "0", padding: "8px"}}
            size="large"
            icon={<QuestionOutlined />}
            src={avatar ? `${AppConstants.contentRootUrl}${avatar}` : null}
          />
        ),
      },
      { title: "Tên", dataIndex: "questionName" },
      { 
        title: <div>Cấp độ câu hỏi</div>, 
        dataIndex: "questionRank",
        width: 170,
        render: (questionRank) => {
          questionRank =  QuestionRank.find(e=>e.value===questionRank);
          return (
          <span>
            {questionRank ? questionRank.label : ''}
          </span>
          )
        }
      },
      this.renderStatusColumn(),
      this.renderActionColumn(),
    ];
    this.actionColumns = {
      isEdit: true,
      isDelete: true,
      isChangeStatus: false,
    };
    this.competences = {
      name: qs.parse(this.props.location.search).competencesName,
      id: qs.parse(this.props.location.search).competencesId,
    }

  }

  getSearchFields() {
    return [
      {
        key: "questName",
        seachPlaceholder: "Tên",
        initialValue: this.search.questName,
      },
      {
        key: "status",
        seachPlaceholder: "Chọn trạng thái",
        fieldType: FieldTypes.SELECT,
        options: commonStatus,
        initialValue: this.search.status,
      },
    ];
  }
  onSearch(values) {
    if (qs.parse(this.props.location.search).competencesId)
    values.competencesId=qs.parse(this.props.location.search).competencesId

    this.search = values;
    this.pagination.current = 1;
    this.setQueryString();
  } 
  onResetFormSearch() {
    this.search = this.initialSearch();
    if (qs.parse(this.props.location.search).competencesId)
    this.search.competencesId=qs.parse(this.props.location.search).competencesId
    this.pagination.current = 1;
    this.setQueryString();
} 
  renderRouting (page){
    const queryString = qs.parse(this.props.location.search);
    const finalsearch = {};
    if(page === "survey_profile"){
      if (queryString.searchsurveyName) finalsearch.surveyName = queryString.searchsurveyName;
      if (queryString.searchsurveystatus) finalsearch.status = queryString.searchsurveystatus;
    }
    else if(page === "competences"){
      if (queryString.SurveyName) finalsearch.SurveyName = queryString.SurveyName;
      if (queryString.surveyProfileId) finalsearch.surveyProfileId = queryString.surveyProfileId;
      if (queryString.searchsurveyName) finalsearch.searchsurveyName = queryString.searchsurveyName;
      if (queryString.searchsurveystatus) finalsearch.searchsurveystatus = queryString.searchsurveystatus;
      if (queryString.workFieldId) finalsearch.workFieldId = queryString.workFieldId;
      if (queryString.workFieldName) finalsearch.workFieldName = queryString.workFieldName;

      if (queryString.searchCompetencesName) finalsearch.competencesName = queryString.searchCompetencesName;
      if (queryString.searchCompetencesStatus) finalsearch.status = queryString.searchCompetencesStatus;
    }
    this.props.history.push(`/${page}?${qs.stringify(finalsearch)}`);
  }

  render() {
    const {
      dataList,
      loading,
      uploadFile,
    } = this.props;
    const { isShowModifiedModal, isShowModifiedLoading } = this.state;
    const question = dataList.data || [];
    this.pagination.total = dataList.totalElements || 0;
    const queryString = qs.parse(this.props.location.search);
    return (
      <div>
        {this.renderSearchForm()}
        <div className="action-bar province">
        <span style={{color: "#40a9ff"}}>

        <span style={{cursor: "pointer", margin: "0 5px", borderWidth: 0, background: "none"}} onClick={()=>this.renderRouting("survey_profile")}><Tag>Tất cả</Tag></span>
        <span style={{margin: "0 5px", color: '#ccc'}}>></span>
        <span style={{cursor: "pointer", margin: "0 5px", borderWidth: 0, background: "none"}} onClick={()=>{this.renderRouting("competences")}} >
        <Tag >{queryString.SurveyName}</Tag>
          {/* <span style={{margin: "0 5px", color: '#ccc'}}>-</span>
          <Tag>{queryString.CompetencesName}</Tag> */}
        </span>
        
        <span style={{margin: "0 5px", color: '#ccc'}}>-</span>
        <span style={{cursor: "pointer", margin: "0 5px", borderWidth: 0, background: "none"}} onClick={()=>{this.renderRouting("competences")}} >
        <Tag >{queryString.workFieldName}</Tag>
        </span>
        <span style={{margin: "0 5px", color: '#ccc'}}>></span>
        <span style={{color: "#000"}}> <Tag>{queryString.competencesName || ""}</Tag></span>
        </span> 
          {

            <Button type="primary" onClick={() => this.onShowModifiedModal(false)}>
                    <PlusOutlined />  Câu hỏi mới
                    </Button>
          }
        </div>
        <BaseTable
          loading={loading}
          columns={this.columns}
          rowKey={(record) => record.id}
          dataSource={question}
          pagination={this.pagination}
          onChange={this.handleTableChange}
        />
        <BasicModal
          visible={isShowModifiedModal}
          isEditing={this.isEditing}
          objectName={this.objectName}
          loading={isShowModifiedLoading}
          onOk={this.onOkModal}
          onCancel={this.onCancelModal}
        >
          <QuestionForm
            isEditing={this.isEditing}
            dataDetail={this.isEditing ? this.dataDetail : {}}
            loadingSave={isShowModifiedLoading}
            uploadFile={uploadFile}
            competences={this.competences || {}}
          />
        </BasicModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.question.tbQuestionLoading,
  dataList: state.question.questionData || {},
});

const mapDispatchToProps = (dispatch) => ({
  getDataList: (payload) => dispatch(actions.getQuestionList(payload)),
  getDataById: (payload) => dispatch(actions.getQuestionById(payload)),
  createData: (payload) => dispatch(actions.createQuestion(payload)),
  updateData: (payload) => dispatch(actions.updateQuestion(payload)),
  deleteData: (payload) => dispatch(actions.deleteQuestion(payload)),
  uploadFile: (payload) => dispatch(actions.uploadFile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionListPage);
