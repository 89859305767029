import React from "react";
import { connect } from "react-redux";
import { Avatar, Tag, Divider, Button } from "antd";
import { UserOutlined, EyeOutlined,} from "@ant-design/icons";

import ListBasePage from "../ListBasePage";
import ExamDetailForm from "../../compoments/exam/ExamDetailForm";

import BaseTable from "../../compoments/common/table/BaseTable";
import ModalMap from "../../compoments/common/modal/ModalMap";
import { sitePathConfig } from '../../constants/sitePathConfig';

import { actions } from "../../actions";
import { AppConstants } from "../../constants";
import ElementWithPermission from '../../compoments/common/elements/ElementWithPermission';
import { showErrorMessage } from '../../services/notifyService';
import { convertUtcToLocalTime } from "../../utils/datetimeHelper";

const commonStatus = [
  { value: 1, label: 'Kích hoạt', color: 'green' },
  { value: 0, label: 'Đang khóa', color: 'red' },
]

class ExamListPage extends ListBasePage {
  initialSearch() {
     return {}
  }

  constructor(props) {
    super(props);
    this.state = {
      isShowDetailModal: false,
    }
    this.objectName = "exam";
    this.breadcrumbs = [{ name: "Bài kiểm tra" }];
    this.columns = [
      this.renderIdColumn(),
      {
        title: "Huy hiệu",
        dataIndex: "profile",
        render: (profile) => (
          <Avatar
            style={{width: "70px", height: "70px", padding: "8px"}}
            className="customer-avatar"
            size="large"
            icon={<UserOutlined />}
            src={profile.surveyImage ? `${AppConstants.contentRootUrl}${profile.surveyImage}` : null}
          />
        ),
      },
      { 
        title: "Nhóm ",
        render: (dataRow) => (
          <div>{dataRow.profile.surveyName} - {dataRow.area.workTitle}</div>
        ),
      },
      this.renderCustomerNameColumn(),
      { 
        title: "Ngày bắt đầu", 
        dataIndex: "createdDate", 
        render: (createdDate) => (
        <div>
          {convertUtcToLocalTime(createdDate, "DD/MM/YYYY HH:mm:ss", "DD/MM/YYYY HH:mm:ss")}
        </div>
      )},
      this.renderIsFinishColumn(),
      this.renderActionColumn(),
    ];
    this.actionColumns = {
      isShowHistory: true,
    };
  }

  renderShowHistoryButton = (children) => {
    const { location : { pathname }} = this.props;
    const requiredPermissions = [];
    Object.keys(sitePathConfig) && Object.keys(sitePathConfig).forEach(key=>{
        if(sitePathConfig[key].path === pathname){
            requiredPermissions.push(sitePathConfig[key].permissions[5])
        }
    })
    return (<ElementWithPermission permissions={requiredPermissions}>
        {children}
    </ElementWithPermission>)
  }

  renderActionColumn() {
    return {
        title: 'Hành động',
        width: '100px',
        align: 'center',
        render: (dataRow) => {
            const actionColumns = [];
            if(this.actionColumns.isShowHistory) {
              actionColumns.push(this.renderShowHistoryButton((
                <Button type="link" onClick={() => this.onShowDetailModal(dataRow)} className="no-padding">
                  <EyeOutlined />
                </Button>
              )))
            }
            const actionColumnsWithDivider = [];
            actionColumns.forEach((action, index) => {
                actionColumnsWithDivider.push(action);
                if(index !== (actionColumns.length -1))
                {
                    actionColumnsWithDivider.push(<Divider type="vertical" />);
                }
            })
            return (
                <span>
                    {
                        actionColumnsWithDivider.map((action, index) => <span key={index}>{action}</span>)
                    }
                </span>
            )
        }
    }
      
  }
  renderIsFinishColumn = () => {
    return {
        title: 'Trạng thái',
        dataIndex: 'isFinish',
        align: 'center',
        render:(isFinish)=>
          isFinish===1?<div>Hoàn thành</div>:<div>Chưa hoàn thành</div> 
    }
  }
  renderCustomerNameColumn = () =>
  {
    return{
      title: 'Tên khách hàng',
      dataIndex: 'customer',
      render:(customer)=>{
        return(
        <div>{customer.fullName}</div>)
        }
    }
  }
  renderStatusColumn() {
    return {
        title: 'Trạng thái',
        dataIndex: 'status',
        width: '100px',
        render: (status) => {
          const statusItem = commonStatus.find(s=>s.value === status);
          return (
            <Tag className="tag-status" color={statusItem.color}>
                {statusItem.label}
            </Tag>
          )
        }
    }
  }

onShowDetailModal = (dataRow) => {
  this.modalCustomerId = dataRow.id;
  this.setState({ isShowDetailModal: true });
}
onCancelDetailModal = () => {
  this.modalCustomerId = undefined;
  this.setState({ isShowDetailModal: false });
}

getDetailAdminByExamId = (dataRow) => {
  const { getDetailsAdminList } = this.props;
  let params;
  if(dataRow.params)
  {
    params = { 
      id: this.modalCustomerId,
      page: dataRow.params.page,
      size: dataRow.params.size,
      search: dataRow.params.search,
    };
  }
  else{
    params = { 
      id: this.modalCustomerId,
    };
  }
  getDetailsAdminList({
      params,
  });
}
  render() {
    const {
      dataList,
      loading,
      detailsAdminData,
      detailsAdminLoading,        
    } = this.props;
    const { isShowDetailModal, } = this.state;
    const customer = dataList.data || [];
    this.pagination.total = dataList.totalElements || 0;
    return (
      <div>
        <BaseTable
          loading={loading}
          detailsAdminLoading={detailsAdminLoading}
          columns={this.columns}
          rowKey={(record) => record.id}
          dataSource={customer}
          pagination={this.pagination}
          onChange={this.handleTableChange}
        />
        <ModalMap
          visible={isShowDetailModal}
          title={"Chi tiết bài kiểm tra"}
          onCancel={this.onCancelDetailModal}
          width={1200}
          loading={true}
        >
          <ExamDetailForm
            dataList = {detailsAdminData}
            loading={detailsAdminLoading}
            getDataList={this.getDetailAdminByExamId||[]}
          />

        </ModalMap>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.exam.listAdminLoading,
  dataList: state.exam.listAdminData || {},
  detailsAdminData: state.exam.detailsAdminData || {},
  detailsAdminLoading: state.exam.detailsAdminLoading,

});

const mapDispatchToProps = (dispatch) => ({
  getDataList: (payload) => dispatch(actions.getListAdminList(payload)),
  getDetailsAdminList: (payload) => dispatch(actions.getDetailsAdminList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamListPage);
