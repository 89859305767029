import React from 'react';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom';

import { sitePathConfig } from '../constants/sitePathConfig';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import LoginPage from '../containers/account/LoginPage';
import ProfilePage from '../containers/account/ProfilePage';

// import DashBoard from '../containers/Dashboard';
import UserAdminListPage from '../containers/users/UserAdminListPage';

import NotFound from '../compoments/common/NotFound';
import Forbidden from '../containers/Forbidden';
// import ErrorServer from '../containers/ErrorServer';
// import Layout from '../components/layout/Layout';
import SettingsListPage from '../containers/settings/SettingsListPage';
import GroupPermissionListPage from '../containers/groupPermission/GroupPermissionListPage';
import ProvinceListPage from '../containers/province/ProvinceListPage';


import CategoryListPage from '../containers/category/CategoryListPage';
import CategoryListPageChild from '../containers/category/CategoryListPageChild';
import CategoryUniversityListPage from '../containers/category/CategoryUniversityListPage';
import CategoryUniversityListPageChild from '../containers/category/CategoryUniversityListPageChild';
import CategoryJobListPage from '../containers/category/CategoryJobListPage';
// import ServiceListPageChild from "../containers/service/ServiceListPageChild"
import NewsListPage from "../containers/news/NewsListPage";
import CustomerListPage from "../containers/customer/CustomerListPage";
import AreaListPage from '../containers/area/AreaListPage';
import SurveyProfileListPage from "../containers/surveyProfile/SurveyProfileListPage";
import QuestionListPage from "../containers/question/QuestionListPage";
import CompetencesListPage from '../containers/competences/CompetencesListPage';
import ExamListPage from '../containers/exam/ExamListPage';
import ExamListPageByCustomer from '../containers/exam/ExamListPageByCustomer';

const RootRoute = () => {
    const {
        admin,
        login,
        profile,
        forbidden,
        setting,
        groupPermission,
        province,
        category,
        news,
        customer,
        area,
        surveyProfile,
        question,
        competences,
        exam,
        examByCustomer,
        categoryuniversity,
        categoryjob,
    } = sitePathConfig;

    return (
        <BrowserRouter>
            <Switch>
                {/* <Redirect exact from="/" to="/delivery/deliveryorder"/>
                {
                    routes.map((MyRoute, index) => ({...MyRoute, key: index}))
                } */}
                <Redirect exact from="/" to={{
                    pathname: admin.path,
                    state: { isRedirectToHomePage: true }
                }}/>
                <PublicRoute exact path={login.path} component={LoginPage} />
                <PrivateRoute exact path={profile.path} component={ProfilePage}/>
                <PrivateRoute exact path={admin.path} component={UserAdminListPage}/>

                <PrivateRoute exact path={setting.path} component={SettingsListPage}/>
                <PrivateRoute exact path={groupPermission.path} component={GroupPermissionListPage}/>
                <PrivateRoute exact path={province.path} component={ProvinceListPage}/>
                <PrivateRoute exact path={category.path} component={CategoryListPage}/>
                <PrivateRoute path={category.path} component={CategoryListPageChild}/>
                <PrivateRoute exact path={categoryuniversity.path} component={CategoryUniversityListPage}/>
                <PrivateRoute path={categoryuniversity.path} component={CategoryUniversityListPageChild}/>
                <PrivateRoute exact path={categoryjob.path} component={CategoryJobListPage}/>
                <PrivateRoute path={news.path} component={NewsListPage}/>
                <PrivateRoute path={customer.path} component={CustomerListPage}/>
                <PrivateRoute path={area.path} component={AreaListPage}/>
                <PrivateRoute path={surveyProfile.path} component={SurveyProfileListPage}/>
                <PrivateRoute path={question.path} component={QuestionListPage}/>
                <PrivateRoute path={competences.path} component={CompetencesListPage}/>   
                <PrivateRoute path={exam.path} component={ExamListPage}/>   
                <PrivateRoute path={examByCustomer.path} component={ExamListPageByCustomer}/>   



                {/* Error Page */}
                <PrivateRoute exact path={forbidden.path} component={Forbidden}/>
                {/* <Route exact path="/error" component={ErrorServer} /> */}   
                {/* 404 Page */}
                <PublicRoute component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}

export default RootRoute;
