import React, { useState } from "react";
import Slider from "react-slick";
import { Spin } from "antd";
import { CloseCircleFilled, DropboxOutlined } from '@ant-design/icons';
import {
    AppConstants,
  } from "../../../constants";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Carousel = ({
    images,
    currentImage,
    handleChangeCurrentImage,
    handleRemoveImage,
}) => {

    const settings = {
        centerMode: false,
        dots: true,
        infinite: false,
        slidesToShow: 5,
        speed: 300,
        swipeToSlide: true,
        focusOnSelect: true,
    };

    const [imgsLoading, setImgsLoading] = useState(Object.keys(images).map(img=>true) || []);

    const imageLoaded = (i) => {
        const newImgsLoading = [...imgsLoading];
        newImgsLoading[i] = false;
        setImgsLoading(newImgsLoading);
    }

    const renderSlider = () => {
        return (
            <Slider {...settings}>
            {
                images && Object.keys(images)?.map((e, i)=>(
                    <div key={images[e].path}>
                        <Spin spinning={imgsLoading[i]}>
                            <div
                                className="img-ctn"
                                style={{
                                    border: e===currentImage ? "#1890ff 1.5px dashed" : "rgba(204, 204, 204, 0.5) 1.5px dashed",
                                }}
                                onClick={()=>{handleChangeCurrentImage(e)}}
                            >
                                <div className="img-overlay" style={{
                                    backgroundImage: `url(${AppConstants.contentRootUrl}${images[e].path})`,
                                    }}></div>
                                <CloseCircleFilled className="del-img" onClick={()=>{handleRemoveImage(e)}}/>
                                <img onLoad={()=>{imageLoaded(i)}} src={`${AppConstants.contentRootUrl}${images[e].path}`} style={{visibility: 'hidden', width: '100%', height: '100%'}}/>
                            </div>
                        </Spin>
                    </div>
                ))
            }
            </Slider>
        )
    }

    return (
      <div className="ctn-slider"
        style={ Object.keys(images).length <= 0 ? {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignitems: 'center',
        } : {}}
      >
          { Object.keys(images).length <= 0 ? <DropboxOutlined style={{ 
              fontSize: 36,
               color: '#ccc'
          }}/> : null }
        {renderSlider()}
      </div>
    );
}

export default React.memo(Carousel);