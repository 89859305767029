import { combineReducers } from 'redux';
import appCommon from './appCommon';
import account from './account';
import user from './user';
import groupPermission from './groupPermission';
import settings from './setting';
import province from './province';
import category from './category'
import news from './news';
import customer from './customer';
import area from './area';
import surveyProfile from './surveyProfile';
import question from './question';
import competences from './competences';
import exam from './exam';
const rootReducer = combineReducers({
    appCommon: appCommon.reducer,
    account: account.reducer,
    user: user.reducer,

    groupPermission: groupPermission.reducer,
    settings: settings.reducer,
    province: province.reducer,
    category: category.reducer,

    news: news.reducer,
    customer: customer.reducer,
    area: area.reducer,
    surveyProfile: surveyProfile.reducer,
    question: question.reducer,
    competences: competences.reducer,
    exam: exam.reducer,
});

export default rootReducer;