import { actions as appCommonActions, actionTypes as appCommonTypes } from './appCommon';
import { actions as accountActions, actionTypes as accountTypes } from './account';
import { actions as userActions, actionTypes as userTypes } from './user';
import { actions as groupPermissionActions, actionTypes as groupPermissionTypes } from './groupPermission';
import {actions as settingActions, actionTypes as settingTypes} from './setting';
import {actions as provinceActions, actionTypes as provinceTypes} from './province';

import {actions as categoryActions, actionTypes as categoryActionsTypes} from "./category";
import {actions as newsActions, actionTypes as newsActionsTypes} from "./news";
import {actions as customerActions, actionTypes as customerActionsTypes} from "./customer";
import {actions as areaActions, actionTypes as areaActionsTypes} from "./area";
import {actions as surveyProfileActions, actionTypes as surveyProfileActionsTypes} from "./surveyProfile";
import {actions as questionActions, actionTypes as questionActionsTypes} from "./question";
import {actions as competencesActions, actionTypes as competencesActionsTypes} from "./competences";
import {actions as examActions, actionTypes as examActionsTypes} from "./exam";

export const actions = {
    ...appCommonActions,
    ...accountActions,
    ...userActions,
    ...groupPermissionActions,
    ...settingActions,
    ...provinceActions,
    ...categoryActions,
    ...newsActions,
    ...customerActions,
    ...areaActions,
    ...surveyProfileActions,
    ...questionActions,
    ...competencesActions,
    ...examActions,

}

export const types = {
    ...appCommonTypes,
    ...accountTypes,
    ...userTypes,
    ...groupPermissionTypes,
    ...settingTypes,
    ...provinceTypes,
    ...categoryActionsTypes,
    ...newsActionsTypes,
    ...customerActionsTypes,
    ...areaActionsTypes,
    ...surveyProfileActionsTypes,
    ...questionActionsTypes,
    ...competencesActionsTypes,
    ...examActionsTypes,
}