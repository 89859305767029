import { all } from 'redux-saga/effects';
import appCommon from './appCommon';
import account from './account';
import user from './user';
import groupPermission from './groupPermission';
import setting from './setting';
import province from './province';
import category from './category';
import news from './news';
import customer from './customer';
import area from './area';
import surveyProfile from './surveyProfile';
import question from './question';
import competences from './competences';
import exam from './exam';
const sagas = [
    ...appCommon,
    ...account,
    ...user,
    ...groupPermission,

    ...setting,
    ...province,
    ...category,
    ...news,
    ...customer,
    ...area,
    ...surveyProfile,
    ...question,
    ...competences,
    ...exam,
];

function* rootSaga() {
    yield all(sagas);
}

export default rootSaga;
