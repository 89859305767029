import React, { useMemo } from 'react';
import { Table } from 'antd';
import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import BaseTable from '../common/table/BaseTable';

function MergedRowTable({
    data,
    loading,
}) {
    const dataList = useMemo(() => {
        let temp = data?.reduce((r, a) => {
                r[a.competence.id] = {
                    index: r.length-1,
                    competenceId: a.competence.id,
                    competenceName: a.competence.competenceName,
                    competencesArray: [
                        ...r[a.competence.id]?.competencesArray || [], 
                        {
                            index: a.index,
                            competenceName: a.competence.competenceName,
                            competenceId: a.competence.id,
                            questionName: a.question.name,
                            isRight: a.isRight,
                        }
                    ]
                }
                return r;
               }, []) || {};
        return Object.values(temp);
    }, [data])

    const columns = useMemo(() => {
        return [
            {
                title: 'Năng lực',
                width: '25%',
                className: 'competenceName',
                render: (competenceName) => (<div className="area" style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <div style={{width:"100%"}}>{competenceName.competenceName}</div>
                    </div>
                ),
            },
            {
                title: (
                <div className="title-th" style={{
                    display: "flex",
                }} >
                    <div className="title-question" style={{flex: 1}}>Câu hỏi</div>
                    <div className="title-isright" style={{width: 100, textAlign: "center"}}>Kết quả</div>
                </div>),
                dataIndex: 'competencesArray',
                className: "col-competences",
                width: '75%',
                render: (competencesArray, dataRow, index) => {
                    const competencesColumns = [
                        {
                            dataIndex: 'questionName',
                            className: 'questionName',
                            render: (questionName, dataRow, i) => {
                                return (
                                    <div key={dataRow.competenceId} >
                                        {(index + 1) + "." + (i + 1) } {questionName}
                                    </div>
                                )
                            }
                        },
                        { 
                            dataIndex: "isRight",
                            className:"isRight",
                            width: 100,

                            align: "center",
                            render: (isRight, dataRow, i) => (
                              isRight===1?<CheckOutlined style={{color:"green"}} />:<StopOutlined style={{color:"red"}}/>
                            ),
                          },
                    ]
                    return (
                        <Table
                            style={{minWidth:500}}
                            className={`competences competences--${index}`}
                            columns={competencesColumns}
                            rowKey={(record) => record.competenceId}
                            dataSource={competencesArray || []}
                            pagination={false}
                            showHeader={false}
                            loading={loading}
                        />
                    )
                }
            },
        ];
    }, [dataList])

    const render = (
        <div>
            <div>
                <div>
                    <BaseTable 
                        className="base-table digital-profile-table"
                        size="default"
                        // scroll={{ y: 800 }}
                        loading={loading}
                        columns={columns}
                        rowKey={(record) => record.index}
                        dataSource={dataList || []}
                        pagination={false}
                        style={{minWidth:700}}
                        loading={loading}

                    />
                </div>
            </div>
        </div>
    )

    return render;
}

export default MergedRowTable