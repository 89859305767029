import React from "react";
import { connect } from "react-redux";
import { Button, Tag} from "antd";
import { PlusOutlined, } from "@ant-design/icons";

import ListBasePage from "../ListBasePage";
import CompetencesForm from "../../compoments/competences/CompetencesForm";
import BaseTable from "../../compoments/common/table/BaseTable";
import BasicModal from "../../compoments/common/modal/BasicModal";
import { FieldTypes } from "../../constants/formConfig";
import { commonStatus } from "../../constants/masterData";

import { actions } from "../../actions";

import qs from 'query-string';
import { sitePathConfig } from "../../constants/sitePathConfig";
import CustomDropdownField from "../competences/CustomDropdownField";
class CompetencesListPage extends ListBasePage {
  initialSearch() {
    return { competencesName: "",  status: "", surveyProfileId:"", workFieldId:"", workFieldName: ""}; 
  }

  constructor(props) {
    super(props);
    this.objectName = "Năng lực";
    this.breadcrumbs = [{ name: "Năng lực" }];
    this.columns = [
      this.renderIdColumn(),
      { 
        title: "Tên", 
        render: (dataRow) => {
          return <div style={{cursor: 'pointer', color: "#1890ff"}} onClick={()=>{
            const queryString = qs.parse(this.props.location.search);
            const workFieldName = this.workFieldOptions.find(e=>e.value===Number(queryString.workFieldId));
            if(workFieldName)queryString.workFieldName = workFieldName.label;
            if(queryString.status){
              queryString.searchCompetencesStatus = queryString.status;
              delete queryString.status;
            }
            if(queryString.competencesName){
              queryString.searchCompetencesName = queryString.competencesName;
              delete queryString.competencesName;
            }
            this.props.history.push(`${sitePathConfig.question.path}?competencesName=${dataRow.competencesName}&competencesId=${dataRow.id}&${qs.stringify(queryString)}`);
          }}>{dataRow.competencesName}</div>
        }
      },
      {
        title: "Nhóm",
        dataIndex: "surveyProfileId",
        width: 300,
        render: (surveyProfileId) => {
          let surveyProfile;
          if(this.surveyProfileOptions.length > 0){
            surveyProfile =  this.surveyProfileOptions.find(e=>e.value===surveyProfileId);
          }
          return (
          <span>
            {surveyProfile ? surveyProfile.label : ''}
          </span>
        )},
      },
      {
        title: "Lĩnh vực",
        dataIndex: "workFieldId",
        width: 300,
        render: (workFieldId) => {
          let workField;
          if(this.workFieldOptions.length > 0){
            workField =  this.workFieldOptions.find(e=>e.value===workFieldId);
          }
          return (
          <span>
            {workField ? workField.label : ''}
          </span>
        )},
      },
      this.renderStatusColumn(),
      this.renderActionColumn(),
    ];
    this.actionColumns = {
      isEdit: true,
      isDelete: true,
      isChangeStatus: false,
    };
    this.handleworkFieldChange=this.handleworkFieldChange.bind(this);
    this.checkExisted=this.checkExisted.bind(this);
    this.props.getSurveyProfileAutoCompleteList();
    this.props.getWorkFieldAutoCompleteList();

    this.workFieldOptions = [];
    this.surveyProfileOptions= [];
  }

  prepareCreateData(data) {
    return {
      ...data,
      workTitle:this.dataDetail.name,
      id: this.dataDetail.id

    }
  }

    prepareUpdateData(data) {
      return {
        ...data,
        id: this.dataDetail.id,
        workTitle:this.dataDetail.name,
      }
    }
    
    getSearchFields() {
        return [
            { key: "competencesName", seachPlaceholder: 'Tên năng lực', 
            },

              {
                key: "status",
                seachPlaceholder: "Chọn trạng thái",
                fieldType: FieldTypes.SELECT,
                options: commonStatus,
                initialValue: this.search.status,
              },
        ];
    }
  onSearch(values) {
    if (qs.parse(this.props.location.search).surveyProfileId)
    values.surveyProfileId=qs.parse(this.props.location.search).surveyProfileId
    if (qs.parse(this.props.location.search).workFieldId)
    values.workFieldId=qs.parse(this.props.location.search).workFieldId
    this.search = values;
    this.pagination.current = 1;
    this.setQueryString();
  }

  onResetFormSearch() {
    this.search = this.initialSearch();
    if (qs.parse(this.props.location.search).surveyProfileId)
    this.search.surveyProfileId=qs.parse(this.props.location.search).surveyProfileId
    if (qs.parse(this.props.location.search).workFieldId)
    this.search.workFieldId=qs.parse(this.props.location.search).workFieldId
    this.pagination.current = 1;
    this.setQueryString();
  } 
    renderRouting (){
        let temp = qs.parse(this.props.location.search);
        let finalsearch={}

        if (temp.searchsurveyName) finalsearch={...finalsearch, surveyName: temp.searchsurveyName}
        if (temp.searchsurveystatus) finalsearch={...finalsearch, status: temp.searchsurveystatus}

        if (temp.searchquestName) finalsearch={...finalsearch, searchquestName: temp.searchquestName}
        if (temp.searchqueststatus) finalsearch={...finalsearch, searchqueststatus: temp.searchqueststatus}

        if (temp.competencesName) finalsearch={...finalsearch, searchcompetencesName: temp.competencesName}
        if (temp.status) finalsearch={...finalsearch, searchcompetencesstatus: temp.status}
        
        this.props.history.push(`${sitePathConfig.surveyProfile.path}?${qs.stringify(finalsearch)}`);
    }
    
    handleworkFieldChange(value)
    {
        const temp = qs.parse(this.props.location.search);
        temp.workFieldId=value;
        const workFieldName = this.workFieldOptions.find(e=>e.value===value);
        temp.workFieldName = workFieldName ? workFieldName.label : "";
        this.props.history.push(`${sitePathConfig.competences.path}?${qs.stringify(temp)}`);
        this.getList();
    }
    checkExisted (array, value)
    {
        for (let i = 0; i<array.length;i++)
        {
            if(array[i].id===parseInt(value))
                return array[i].workTitle;
        }
    }
    render() {
    const {
      dataList,
      loading,
      uploadFile,
      workFieldListData,
      surveyProfileListData
    } = this.props;
    const { isShowModifiedModal, isShowModifiedLoading } = this.state;
    this.pagination.total = dataList.totalElements || 0;
    this.workFieldOptions = workFieldListData.data ? workFieldListData.data.map(c=>{
        return {
          value: c.id,
          label: c.workTitle,
        }
      }) : [];
      this.surveyProfileOptions = surveyProfileListData.data ? surveyProfileListData.data.map(c=>{
        return {
          value: c.id,
          label: c.surveyName,
        }
      }) : [];
      let  selectedsurveyProfile = qs.parse(this.props.location.search).surveyProfileId||"";
      let  selectedworkField = qs.parse(this.props.location.search).workFieldId||1036;
    return (
      <div>
        {this.renderSearchForm()}
        <div className="action-bar">
          {
            <div className="action-bar category">   
            <span>
            <span style={{cursor: "pointer", margin: "0 5px", borderWidth: 0, background: "none"}} onClick={()=>this.renderRouting()}><Tag>Tất cả</Tag></span>

            {/* {<button style={{cursor: "pointer", margin: "0 5px", borderWidth: 0, background: "none", color: "#40a9ff"}} onClick={()=>this.renderRouting()}><Tag>Tất cả</Tag></button>} */}
            <span style={{margin: "0 10px 0 5px", color: '#ccc'}}>></span>
            <Tag>{qs.parse(this.props.location.search).SurveyName}</Tag>
            {<span style={{margin: "0 10px 0 5px", color: '#ccc'}}>></span>}
            <Tag>{workFieldListData.data && workFieldListData.data?
            this.checkExisted(workFieldListData.data,selectedworkField)
            :""}</Tag>
            </span> 

            <div>
            <CustomDropdownField 
                placeholder= "Chọn lĩnh vực"
                style={{width:200, marginRight:20, textAlign:"left"}}
                options= {[...this.workFieldOptions]}
                loading= {this.props.workFieldListLoading}
                
                defaultValue={parseInt(selectedworkField)}
                onChange={this.handleworkFieldChange}
            
            />
            <Button type="primary" onClick={() => this.onShowModifiedModal(false)}>
                    <PlusOutlined />  Năng lực mới
                    </Button>
              </div>
              </div>
          }
        </div>
        <BaseTable
          loading={loading}
          columns={this.columns}
          rowKey={(record) => record.id}
          dataSource={dataList&&dataList.competencesListData?dataList.competencesListData.data:null}
          pagination={this.pagination}
          onChange={this.handleTableChange}
        />
        <BasicModal
          visible={isShowModifiedModal}
          isEditing={this.isEditing}
          objectName={this.objectName}
          loading={isShowModifiedLoading}
          onOk={this.onOkModal}
          onCancel={this.onCancelModal}
        >
          <CompetencesForm
            isEditing={this.isEditing}
            dataDetail={this.isEditing ? 
                {...this.dataDetail,
                    name:this.dataDetail.workTitle, 
                    workFieldListData:this.props.workFieldListData, 
                    surveyProfileListData:this.props.surveyProfileListData,
                    selectedsurveyProfile:selectedsurveyProfile,
                    selectedworkField:selectedworkField,
                    workFieldOptions:this.workFieldOptions,
                    surveyProfileOptions:this.surveyProfileOptions,
                } 
                    : 
                    {workFieldListData:this.props.workFieldListData, 
                        surveyProfileListData:this.props.surveyProfileListData,
                        selectedsurveyProfile:selectedsurveyProfile,
                        selectedworkField:selectedworkField,
                        workFieldOptions:this.workFieldOptions,
                        surveyProfileOptions:this.surveyProfileOptions,}}
            uploadFile={uploadFile}
            loadingSave={isShowModifiedLoading}
          />
        </BasicModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.competences.competencesListLoading,
  dataList: state.competences.competencesListData || {},
  surveyProfileListLoading: state.competences.surveyProfileListLoading,
  surveyProfileListData: state.competences.surveyProfileListData || {},
  workFieldListLoading: state.competences.workFieldListLoading,
  workFieldListData: state.competences.workFieldListData || {},
});

const mapDispatchToProps = (dispatch) => ({
  getDataById: (payload) => dispatch(actions.getCompetences(payload)),
  createData: (payload) => dispatch(actions.createCompetences(payload)),
  updateData: (payload) => dispatch(actions.updateCompetences(payload)),
  deleteData: (payload) => dispatch(actions.deleteCompetences(payload)),
  getDataList: (payload) => dispatch(actions.getCompetencesList(payload)),
  getSurveyProfileAutoCompleteList: (payload) => dispatch(actions.getSurveyProfileAutoCompleteList(payload)),
  getWorkFieldAutoCompleteList: (payload) => dispatch(actions.getWorkFieldAutoCompleteList(payload)),

});

export default connect(mapStateToProps, mapDispatchToProps)(CompetencesListPage);
