import React from "react";
import { connect } from "react-redux";
import { Avatar, Tag, Button, Divider } from "antd";
import { UserOutlined, HistoryOutlined, EditOutlined, LockOutlined, CheckOutlined, DeleteOutlined } from "@ant-design/icons";

import ListBasePage from "../ListBasePage";
import CustomerForm from "../../compoments/customer/CustomerForm";
import BaseTable from "../../compoments/common/table/BaseTable";
import BasicModal from "../../compoments/common/modal/BasicModal";
import { STATUS_ACTIVE } from '../../constants';
import { sitePathConfig } from '../../constants/sitePathConfig';
import ElementWithPermission from '../../compoments/common/elements/ElementWithPermission';

import { actions } from "../../actions";
import { FieldTypes } from "../../constants/formConfig";
import { AppConstants } from "../../constants";

const commonStatus = [
  { value: 1, label: 'Kích hoạt', color: 'green' },
  { value: 0, label: 'Đang khóa', color: 'red' },
]

class CustomerListPage extends ListBasePage {
  initialSearch() {
    return { fullName: "", phone: "" , status: undefined };
  }

  constructor(props) {
    super(props);
    this.objectName = 'người dùng';
    this.breadcrumbs = [{ name: "Người dùng" }];
    this.columns = [
      this.renderIdColumn(),
      {
        title: "Ảnh đại diện",
        dataIndex: "avatarPath",
        render: (avatarPath) => (
          <Avatar
            style={{width: "70px", height: "70px", padding: "8px"}}
            className="customer-avatar"
            size="large"
            icon={<UserOutlined />}
            src={avatarPath ? `${AppConstants.contentRootUrl}${avatarPath}` : null}
          />
        ),
      },
      { title: "Tên đăng nhập", dataIndex: "username" },
      { title: "Họ và tên", dataIndex: "fullName" },
      { title: "Số điện thoại", dataIndex: "phone" },
      { title: "E-mail", dataIndex: "email", width: "200px" },
      this.renderStatusColumn(),
      this.renderActionColumn(),
    ];
    this.actionColumns = {
      isEdit: true,
      isDelete: true,
      isShowHistory: true,
      isChangeStatus: false,
    };
  }

  getSearchFields() {
    return [
      {
        key: "fullName",
        seachPlaceholder: "Họ và tên",
        initialValue: this.search.fullName,
      },
      {
        key: "phone",
        seachPlaceholder: "Số điện thoại",
        initialValue: this.search.phone,
      },
      {
        key: "status",
        seachPlaceholder: "Chọn trạng thái",
        fieldType: FieldTypes.SELECT,
        options: commonStatus,
        initialValue: this.search.status,
      },
    ];
  }
  renderShowHistoryButton = (children) => {
    // const { location : { pathname }} = this.props;
    const pathname = sitePathConfig.exam.path;
    const requiredPermissions = [];
    Object.keys(sitePathConfig) && Object.keys(sitePathConfig).forEach(key=>{
        if(sitePathConfig[key].path === pathname){
            requiredPermissions.push(sitePathConfig[key].permissions[0])
        }
    })
    return (<ElementWithPermission permissions={requiredPermissions}>
        {children}
    </ElementWithPermission>)
  }
  renderStatusColumn() {
    return {
        title: 'Trạng thái',
        dataIndex: 'status',
        width: '100px',
        render: (status) => {
          const statusItem = commonStatus.find(s=>s.value === status);
          return (
            <Tag className="tag-status" color={statusItem.color}>
                {statusItem.label}
            </Tag>
          )
        }
    }
  }
  renderActionColumn() {
    return {
        title: 'Hành động',
        width: '100px',
        render: (dataRow) => {
            const actionColumns = [];
            if(this.actionColumns.isShowHistory) {
              actionColumns.push(this.renderShowHistoryButton((
                  <Button type="link" onClick={() => this.props.history.push(`${sitePathConfig.examByCustomer.path}?customerId=${dataRow.id}`)} className="no-padding">
                      <HistoryOutlined />
                  </Button>
              )))
            }
            if(this.actionColumns.isEdit) {
                actionColumns.push(this.renderEditButton((
                    <Button type="link" onClick={() => this.getDetail(dataRow.id)} className="no-padding">
                        <EditOutlined/>
                    </Button>
                )))
            }
            if(this.actionColumns.isChangeStatus) {
                actionColumns.push(
                    <Button type="link" onClick={() => this.showChangeStatusConfirm(dataRow) } className="no-padding">
                        {
                            dataRow.status === STATUS_ACTIVE
                            ?
                            <LockOutlined/>
                            :
                            <CheckOutlined/>
                        }
                    </Button>
                )
            }
            if(this.actionColumns.isDelete) {
                actionColumns.push(
                    this.renderDeleteButton((
                        <Button type="link" onClick={() => this.showDeleteConfirm(dataRow.id) } className="no-padding">
                            <DeleteOutlined/>
                        </Button>
                    ))
                )
            }
            const actionColumnsWithDivider = [];
            actionColumns.forEach((action, index) => {
                actionColumnsWithDivider.push(action);
                if(index !== (actionColumns.length -1))
                {
                    actionColumnsWithDivider.push(<Divider type="vertical" />);
                }
            })
            return (
                <span>
                    {
                        actionColumnsWithDivider.map((action, index) => <span key={index}>{action}</span>)
                    }
                </span>
            )
        }
    }
      
  }
  render() {
    const {
      dataList,
      loading,
      uploadFile,
      getProvinceCombobox,
      provinceCombobox,
      districtCombobox,
      wardCombobox,
    } = this.props;
    const { isShowModifiedModal, isShowModifiedLoading } = this.state;
    const customer = dataList.data || [];
    this.pagination.total = dataList.totalElements || 0;
    return (
      <div>
        {this.renderSearchForm()}
        <div className="action-bar">
        </div>
        <BaseTable
          loading={loading}
          columns={this.columns}
          rowKey={(record) => record.id}
          dataSource={customer}
          pagination={this.pagination}
          onChange={this.handleTableChange}
        />
        <BasicModal
          visible={isShowModifiedModal}
          isEditing={this.isEditing}
          objectName={this.objectName}
          loading={isShowModifiedLoading}
          onOk={this.onOkModal}
          onCancel={this.onCancelModal}
        >
          <CustomerForm
            isEditing={this.isEditing}
            dataDetail={this.isEditing ? this.dataDetail : {}}
            uploadFile={uploadFile}
            getProvinceCombobox={getProvinceCombobox}
            provinceCombobox={provinceCombobox}
            districtCombobox={districtCombobox}
            wardCombobox={wardCombobox}
            commonStatus={commonStatus}
            loadingSave={isShowModifiedLoading}
          />
        </BasicModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.customer.tbCustomerLoading,
  dataList: state.customer.customerData || {},
  provinceCombobox: state.province.provinceCombobox || [],
  districtCombobox: state.province.districtCombobox || [],
  wardCombobox: state.province.wardCombobox || []
});

const mapDispatchToProps = (dispatch) => ({
  getDataList: (payload) => dispatch(actions.getCustomerList(payload)),
  getDataById: (payload) => dispatch(actions.getCustomerById(payload)),
  updateData: (payload) => dispatch(actions.updateCustomer(payload)),
  deleteData: (payload) => dispatch(actions.deleteCustomer(payload)),
  uploadFile: (payload) => dispatch(actions.uploadFile(payload)),
  getProvinceCombobox: (payload) => dispatch(actions.getProvinceCombobox(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListPage);
