import { call, put, takeLatest } from 'redux-saga/effects';

import { sendRequest } from '../services/apiService';
import { actionTypes, reduxUtil } from '../actions/exam';
import apiConfig from '../constants/apiConfig';

const { defineActionLoading, defineActionSuccess, defineActionFailed } = reduxUtil;

const {
    DETAILS_ADMIN,
    LIST_ADMIN,
} = actionTypes;


function* getDetailsAdminList({ payload: { params } }) {

    const apiParams = apiConfig.exam.getDetailsAdminList;
    const searchParams = { page: params.page, size: params.size, examId:params.id };

    try {
        const result = yield call(sendRequest, apiParams, searchParams);
        yield put({
            type: defineActionSuccess(DETAILS_ADMIN),
            detailsAdminData: result.responseData && result.responseData.data,
        });
    }
    catch(error) {
        yield put({ type: defineActionFailed(DETAILS_ADMIN) });
    }
}

function* getListAdminList({ payload: { params } }) {

    const apiParams = apiConfig.exam.getListAdminList;
    const searchParams = { page: params.page, size: params.size,  }; //customerId: params.customerId
    // const searchParams = {};

    if(params.search) {
        if(params.search.customerId) {
            searchParams.customerId = params.search.customerId
        }
    }
    try {
        const result = yield call(sendRequest, apiParams, searchParams);
        yield put({
            type: defineActionSuccess(LIST_ADMIN),
            listAdminData: result.responseData && result.responseData.data,
        });
    }
    catch(error) {
        yield put({ type: defineActionFailed(LIST_ADMIN) });
    }
}

const sagas = [
    takeLatest(defineActionLoading(DETAILS_ADMIN), getDetailsAdminList),
    takeLatest(defineActionLoading(LIST_ADMIN), getListAdminList),

]

export default sagas;