import React from "react";
import { connect } from "react-redux";
import { Button, Avatar } from "antd";
import { PlusOutlined, FileTextOutlined } from "@ant-design/icons";

import ListBasePage from "../ListBasePage";
import AreaForm from "../../compoments/area/AreaForm";
import BaseTable from "../../compoments/common/table/BaseTable";
import BasicModal from "../../compoments/common/modal/BasicModal";
import { FieldTypes } from "../../constants/formConfig";
import { commonStatus } from "../../constants/masterData";

import { actions } from "../../actions";
import {
  AppConstants,
} from "../../constants";

class AreaListPage extends ListBasePage {
  initialSearch() {
    return { title: "", status: undefined};
  }

  constructor(props) {
    super(props);
    this.objectName = "Lĩnh vực";
    this.breadcrumbs = [{ name: "Lĩnh vực" }];
    this.columns = [
      this.renderIdColumn(),
      {
        title: "Ảnh đại diện",
        dataIndex: "workImage",
        align: "center",
        width: 110,
        render: (avatar) => (
          <Avatar
            style={{width: "100px", height: "100px", borderRadius: "0", padding: "8px"}}
            size="large"
            icon={<FileTextOutlined />}
            src={avatar ? `${AppConstants.contentRootUrl}${avatar}` : null}
          />
        ),
      },
      { title: "Tiêu đề", dataIndex: "workTitle" },
      this.renderStatusColumn(),
      this.renderActionColumn(),
    ];
    this.actionColumns = {
      isEdit: true,
      isDelete: true,
      isChangeStatus: false,
    };
  }

  getSearchFields() {
    return [
      {
        key: "title",
        seachPlaceholder: "Chọn Tiêu đề",
        initialValue: this.search.title,
      },
      {
        key: "status",
        seachPlaceholder: "Chọn trạng thái",
        fieldType: FieldTypes.SELECT,
        options: commonStatus,
        initialValue: this.search.status,
      },
    ];
  }

  prepareCreateData(data) {
    return {
      ...data,
      workTitle:this.dataDetail.name,
      id: this.dataDetail.id

    }
  }

    prepareUpdateData(data) {
      return {
        ...data,
        id: this.dataDetail.id,
        workTitle:this.dataDetail.name,
      }
    }


  render() {
    const {
      dataList,
      loading,
      uploadFile,
    } = this.props;
    const { isShowModifiedModal, isShowModifiedLoading } = this.state;
    this.pagination.total = dataList.totalElements || 0;
    return (
      <div>
        {this.renderSearchForm()}
        <div className="action-bar">
          {
            this.renderCreateNewButton((
              <Button
                type="primary"
                onClick={() => this.onShowModifiedModal(false)}
              >
                <PlusOutlined /> Lĩnh vực mới
              </Button>
            ))
          }
        </div>
        <BaseTable
          loading={loading}
          columns={this.columns}
          rowKey={(record) => record.id}
          dataSource={dataList&&dataList.areaListData?dataList.areaListData.data:null}
          pagination={this.pagination}
          onChange={this.handleTableChange}
        />
        <BasicModal
          visible={isShowModifiedModal}
          isEditing={this.isEditing}
          objectName={this.objectName}
          loading={isShowModifiedLoading}
          onOk={this.onOkModal}
          onCancel={this.onCancelModal}
        >
          <AreaForm
            isEditing={this.isEditing}
            dataDetail={this.isEditing ? {...this.dataDetail,name:this.dataDetail.workTitle} : {}}
            categoryOptions={this.categoryOptions}
            uploadFile={uploadFile}
            loadingSave={isShowModifiedLoading}
          />
        </BasicModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.area.areaListLoading,
  dataList: state.area.areaListData || {},
});

const mapDispatchToProps = (dispatch) => ({
  getDataById: (payload) => dispatch(actions.getArea(payload)),
  createData: (payload) => dispatch(actions.createArea(payload)),
  updateData: (payload) => dispatch(actions.updateArea(payload)),
  deleteData: (payload) => dispatch(actions.deleteArea(payload)),
  getDataList: (payload) => dispatch(actions.getAreaList(payload)),

  uploadFile: (payload) => dispatch(actions.uploadFile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AreaListPage);
