import { actionTypes, reduxUtil } from '../actions/exam';

const { createReducer, defineActionSuccess, defineActionLoading, } = reduxUtil;
const {
    DETAILS_ADMIN,
    LIST_ADMIN,
} = actionTypes;

const initialState = { 
    detailsAdminData: {},
    detailsAdminLoading: false,

    listAdminData: {},
    listAdminLoading: false,
};

const reducer = createReducer({
    [defineActionLoading(DETAILS_ADMIN)]: (state) => {
        return {
            ...state,
            detailsAdminData:{},
            detailsAdminLoading: true
        }
    },
    [defineActionSuccess(DETAILS_ADMIN)]: (state, { detailsAdminData }) => {
        return {
            ...state,
            detailsAdminData,
            detailsAdminLoading: false
        }
    },

    [defineActionLoading(LIST_ADMIN)]: (state) => {
        return {
            ...state,
            listAdminLoading: true
        }
    },
    [defineActionSuccess(LIST_ADMIN)]: (state, { listAdminData }) => {
        return {
            ...state,
            listAdminData,
            listAdminLoading: false
        }
    },

    initialState
})

export default {
    reducer
};
