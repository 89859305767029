import { call, put, takeLatest } from "redux-saga/effects";
import {actionTypes, reduxUtil } from "../actions/competences";

import apiConfig from "../constants/apiConfig";

import { sendRequest } from "../services/apiService";
import { handleApiResponse } from "../utils/apiHelper";
const {defineActionLoading, defineActionSuccess, defineActionFailed} = reduxUtil;

const {
    GET_COMPETENCES_LIST,
    DELETE_COMPETENCES,
    GET_SURVEYPROFILE_AUTOCOMPLETE_LIST,
    GET_WORKFIELD_AUTOCOMPLETE_LIST,
} = actionTypes;

function* getCompetencesList({ payload: {params} }){
    const apiParams = apiConfig.competences.getCompetencesList;
    const searchParams = { page: params.page, size: params.size };

    if (params.search)
    {
        if (params.search.competencesName)
            searchParams.competencesName = params.search.competencesName;
        if (params.search.workFieldId)
            searchParams.workFieldId = params.search.workFieldId;
        if(params.search.status)
            searchParams.status = params.search.status;
        if(params.search.surveyProfileId)
            searchParams.surveyProfileId=params.search.surveyProfileId;        
    }


    try {
        const result = yield call (sendRequest, apiParams, searchParams);
        yield put ({
            type:defineActionSuccess(GET_COMPETENCES_LIST),
            competencesListData: result.responseData && {
                ...result.responseData.data,
            },
        })
    }
    catch(error) {
        yield put({ type: defineActionFailed(GET_COMPETENCES_LIST) });
    }
}

function* getCompetences ({payload:{params, onCompleted, onError}})
{
    try {
        const apiParams = {
            ...apiConfig.competences.getById,
            path: `${apiConfig.competences.getById.path}/${params.id}`
        }
        const result = yield call(sendRequest, apiParams);
        handleApiResponse(result,onCompleted,onError);
    }
    catch(error)
    {
        onError(error);
    }
}

function* createCompetences({payload: {params, onCompleted, onError}})
{
    try{
        const result = yield call (sendRequest, apiConfig.competences.create, params);
        handleApiResponse(result, onCompleted, onError);
    }
    catch(error)
    {
        onError(error);
    }
}

function* updateCompetences({payload: {params, onCompleted, onError}})
{
    try{
        const result = yield call (sendRequest, apiConfig.competences.update, params);
        handleApiResponse(result, onCompleted, onError);
    }
    catch(error)
    {
        onError(error);
    }
}

function* deleteCompetences({payload: {params, onCompleted, onError}})
{
    try{
        const apiParams = {
            ...apiConfig.competences.delete,
            path: `${apiConfig.competences.delete.path}/${params.id}`
        }
        const result = yield call (sendRequest, apiParams);
        handleApiResponse(result, onCompleted, onError);

        const { success, responseData } = result;
        if(!success || !responseData.result)
            yield put({ type: defineActionFailed(DELETE_COMPETENCES) });
    }
    catch(error)
    {
        yield put({ type: defineActionFailed(DELETE_COMPETENCES) });
        onError(error);
    }
}
function* getAutoCompleteListSurveyProfile(){
    const apiParams = apiConfig.surveyProfile.getListAutoComplete;
    try {
        const result = yield call (sendRequest, apiParams);
        yield put ({
            type:defineActionSuccess(GET_SURVEYPROFILE_AUTOCOMPLETE_LIST),
            surveyProfileListData: result.responseData && {
                ...result.responseData.data,
            },
            
        })
    }
    catch(error) {
        yield put({ type: defineActionFailed(GET_SURVEYPROFILE_AUTOCOMPLETE_LIST) });
    }
}
function* getAutoCompleteListWorkField(){
    const apiParams = apiConfig.area.getListAutoComplete;
    try {
        const result = yield call (sendRequest, apiParams);
        yield put ({
            type:defineActionSuccess(GET_WORKFIELD_AUTOCOMPLETE_LIST),
            workFieldListData: result.responseData && {
                ...result.responseData.data,
            },
            
        })
    }
    catch(error) {
        yield put({ type: defineActionFailed(GET_WORKFIELD_AUTOCOMPLETE_LIST) });
    }
}
const sagas = [
    takeLatest(defineActionLoading(GET_COMPETENCES_LIST), getCompetencesList),
    takeLatest(actionTypes.GET_COMPETENCES, getCompetences),
    takeLatest(actionTypes.CREATE_COMPETENCES, createCompetences),
    takeLatest(actionTypes.UPDATE_COMPETENCES, updateCompetences),
    takeLatest(defineActionLoading(DELETE_COMPETENCES), deleteCompetences),
    takeLatest(defineActionLoading(GET_SURVEYPROFILE_AUTOCOMPLETE_LIST), getAutoCompleteListSurveyProfile), 
    takeLatest(defineActionLoading(GET_WORKFIELD_AUTOCOMPLETE_LIST), getAutoCompleteListWorkField), 

]

export default sagas;