import reduxHelper from '../utils/redux';

export const reduxUtil = reduxHelper('SURVEY_PROFILE');

const { defineAction, createActionWithLoading, createAction } = reduxUtil;

export const actionTypes = {
    GET_SURVEY_PROFILE_LIST: defineAction('GET_SURVEY_PROFILE_LIST'),
    CREATE_SURVEY_PROFILE: defineAction('CREATE_SURVEY_PROFILE'),
    GET_SURVEY_PROFILE_BY_ID: defineAction('GET_SURVEY_PROFILE_BY_ID'),
    UPDATE_SURVEY_PROFILE: defineAction('UPDATE_SURVEY_PROFILE'),
    DELETE_SURVEY_PROFILE: defineAction('DELETE_SURVEY_PROFILE'),
}

export const actions = {
    getSurveyProfileList: createActionWithLoading(actionTypes.GET_SURVEY_PROFILE_LIST),
    createSurveyProfile: createAction(actionTypes.CREATE_SURVEY_PROFILE),
    getSurveyProfileById: createAction(actionTypes.GET_SURVEY_PROFILE_BY_ID),
    updateSurveyProfile: createAction(actionTypes.UPDATE_SURVEY_PROFILE),
    deleteSurveyProfile: createActionWithLoading(actionTypes.DELETE_SURVEY_PROFILE)
}