import React from "react";
import { Form, Col, Row } from "antd";

import BasicForm from "../common/entryForm/BasicForm";
import TextField from "../common/entryForm/TextField";

import { commonStatus,} from "../../constants/masterData";
import DropdownField from "../common/entryForm/DropdownField";



import {
    STATUS_ACTIVE,
  } from "../../constants";

class CompetencesForm extends BasicForm {
  constructor(props) {
    super(props);
    this.state = {
       
      };
  }

  getInitialFormValues = () => {
    const { isEditing, dataDetail } = this.props;
    if (!isEditing) {
      return {
        status: STATUS_ACTIVE,
        workFieldId: dataDetail.selectedworkField,
        surveyProfileId: dataDetail.selectedsurveyProfile,
      };
    }
    return dataDetail;
  };
componentDidMount()
{
  const {dataDetail} = this.props;
  if (dataDetail.selectedworkField)
  this.setFieldValue("workFieldId", parseInt(dataDetail.selectedworkField))
  if (dataDetail.selectedsurveyProfile)
    this.setFieldValue("surveyProfileId", parseInt(dataDetail.selectedsurveyProfile))
}
  render() {
    const { isEditing, formId, dataDetail } = this.props;
    return (
      <Form
        id={formId}
        ref={this.formRef}
        layout="vertical"
        onFinish={this.handleSubmit}
        initialValues={this.getInitialFormValues()}
      >
        <Row gutter={16}>
          <Col span={12}>
            <TextField
              fieldName="competencesName"
              min={6}
              label="Tên"
              required={!isEditing}
            />
          </Col>
          <Col span={12}>
            <TextField fieldName="competencesDescriptionBasic"
            label="Mô tả cấp độ cơ bản" 
            type="textarea"
            style={{height: '120px'}}
            required
          />
          </Col>
        </Row>
        
        <Row gutter={16}>
        <Col span={12}>
            <TextField fieldName="competencesDescriptionMedium"
            label="Mô tả cấp độ trung bình" 
            type="textarea"
            style={{height: '120px'}}
            required
          />
          </Col>
          <Col span={12}>
            <TextField fieldName="competencesDescriptionAdvanced"
            label="Mô tả cấp độ nâng cao" 
            type="textarea"
            style={{height: '120px'}}
            required
          />
          </Col>
        </Row>
        

        <Row gutter={16}>
            
            
          <Col span={12}>
            <DropdownField
              fieldName="status"
              label="Trạng thái"
              required
              options={commonStatus}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <DropdownField
              fieldName="surveyProfileId"
              label="Nhóm"
              required
              options={dataDetail.surveyProfileOptions}
              disabled
            />
          </Col>  
          <Col span={12}>
            <DropdownField
              fieldName="workFieldId"
              label="Lĩnh vực"
              required
              options={dataDetail.workFieldOptions}
              disabled
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default CompetencesForm;
