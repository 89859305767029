import React from "react";
import { Form, Col, Row } from "antd";

import BasicForm from "../common/entryForm/BasicForm";
import TextField from "../common/entryForm/TextField";
import DropdownField from "../common/entryForm/DropdownField";
import ImageFiled from "../common/entryForm/ImageField";

import FileUploadField from "../common/entryForm/FileUploadField";
import { commonStatus, commonQuestionKind, QuestionRank } from "../../constants/masterData";
import {
  AppConstants,
  UploadFileTypes,
} from "../../constants";
import Utils from "../../utils";
import { showErrorMessage } from "../../services/notifyService";
import BaseQuestion from "./BaseQuestion";

class QuestionForm extends BasicForm {
  constructor(props) {
    super(props);
    this.state = {
      avatar: props.dataDetail.questionAvatar
        ? `${AppConstants.contentRootUrl}/${props.dataDetail.questionAvatar}`
        : "",
      avatarpath: props.dataDetail.questionAvatar,
      uploading: false,
      attachmentList: props.dataDetail.questionAttachment ? 
      [{
        uid: '1',
        name: Utils.getFileNameFromPath(props.dataDetail.questionAttachment),
        url: `${AppConstants.contentRootUrl}/${props.dataDetail.questionAttachment}`
      }] :[],
      attachmentPath: undefined,
      questionKind: props.isEditing ? props.dataDetail.questionKind : commonQuestionKind[0].value,
    };
    this.otherData = commonQuestionKind.map(e=>{
      let questionContent = [];
      if(e.value === this.state.questionKind && props.isEditing)
        questionContent = props.dataDetail && props.dataDetail.questionContent && this.isJson(props.dataDetail.questionContent) ? JSON.parse(props.dataDetail.questionContent) : props.dataDetail.questionContent
      return questionContent;
    })
  }

  isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }
  canJsonStringify = (json) => {
    try {
      JSON.stringify(json);
    } catch (e) {
        return false;
    }
    return true;
  }

  componentDidMount(){
    const { competences } = this.props;
    this.setFieldValue("questionCompetencesName", competences.name);
  }

  getInitializeValue = () => {
    const { dataDetail, } = this.props;
    const { questionKind } = this.state;
    return {
      ...dataDetail,
      questionContent: this.otherData[questionKind - 1], //Vì commonQuestionKind value bắt đầu bằng 1 nên phải questionKind - 1
      questionKind
    }
  }

  handleChangeAvatar = (info) => {
    console.log("info",info);
    if (info.file.status === "done") {
      Utils.getBase64(info.file.originFileObj, (avatar) =>
        this.setState({ avatar }),
      );
    }
  };


  uploadFileAvatar = (file, onSuccess) => {
      const { uploadFile } = this.props;
      this.setState({ uploading: true });
      uploadFile({
          params: { fileObjects: { file }, type: UploadFileTypes.AVATAR },
          onCompleted: (result) => {
          
          this.setFieldValue("questionAvatar", result.data.filePath);
          this.setState({avatarpath:result.data.filePath})
          this.setState({ uploading: false });
          onSuccess();
          },
          onError: (err) => {
          if (err && err.message) {
              showErrorMessage(err.message);
              this.setState({ uploading: false });
          }
          },
      });
  };

  handleChangeAttachment = info => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    if(fileList.length <= 0){
      this.setFieldValue("questionAttachment", undefined);
    }
    else{
      fileList[0].name = Utils.getFileNameFromPath(this.state.attachmentPath);
    }
    
    this.setState({ attachmentList: fileList });
    if(info.file.status === "done"){
      this.setFieldValue("questionAttachment", this.state.attachmentPath);
    }
  };

  uploadAttachment = (file, onSuccess) => {
    const { uploadFile } = this.props;
    uploadFile({
        params: { fileObjects: { file }, type: UploadFileTypes.AVATAR },
        onCompleted: (result) => {
        this.setState({ attachmentPath: result.data.filePath });
        onSuccess();
        },
        onError: (err) => {
        if (err && err.message) {
            showErrorMessage(err.message);
        }
        },
    });
  };
  
  handleChangeQuestionKind = (value) => {
    this.setFieldValue("questionContent", this.otherData[value - 1]);
    this.setState({questionKind: value});
  }

  handleSubmit(formValues) { 
    const { onSubmit, competences } = this.props;
    const questionScore = QuestionRank.find(q=>q.value===formValues.questionRank)?.score;
    if (this.state.questionKind===5 && !formValues.questionContent.questions && !formValues.questionContent.answerList)
    {
      if(!formValues.questionContent.questions)
      showErrorMessage("Vui lòng nhập câu hỏi");
      else if(!formValues.questionContent.answerList)
      showErrorMessage("Vui lòng nhập câu trả lời");
    }
    else if (this.state.questionKind===2 || this.state.questionKind===3)
    {
      onSubmit({
        ...formValues,
        questionScore,
        questionCompetencesId: competences.id,
        questionAttachment: formValues.questionAttachment ? formValues.questionAttachment : "",
        questionContent: formValues.questionContent,
        questionAvatar: this.state.avatarpath,
      });
    }
    else
    onSubmit({
      ...formValues,
      questionScore,
      questionCompetencesId: competences.id,
      questionAttachment: formValues.questionAttachment ? formValues.questionAttachment : "",
      questionContent: this.canJsonStringify(formValues.questionContent) ? JSON.stringify(formValues.questionContent) : formValues.questionContent,
      questionAvatar: this.state.avatarpath,
    });
  }

  handleChangeOtherDataFromThis = (questionContent) => {
    const { questionKind } = this.state;
    this.otherData[questionKind - 1] = questionContent;
    this.setFieldValue("questionContent", this.otherData[questionKind - 1]);
  }
  render() {
    const { isEditing, formId, loadingSave, competencesList, uploadFile } = this.props;
    const { uploading, avatar, attachmentList, questionKind } = this.state;
    const isAttachmentRequired = commonQuestionKind[questionKind - 1] && commonQuestionKind[questionKind - 1].isAttachmentRequired;
    return (
      <Form
        id={formId}
        ref={this.formRef}
        layout="vertical"
        onFinish={this.handleSubmit}
        initialValues={this.getInitializeValue()}
      >
        <Row gutter={16}>
            <Col span={12}>
                <ImageFiled
                    fieldName="questionAvatar"
                    loading={uploading}
                    label="Hình"
                    imageUrl={avatar}
                    onChange={this.handleChangeAvatar}
                    uploadFile={this.uploadFileAvatar}
                    required
                    requiredMsg="Hãy đặt hình ảnh cho câu hỏi"
                    disabled={loadingSave}
                />
            </Col>
            <Col span={12}>
                <FileUploadField 
                  fieldName="questionAttachment"
                  label="Tệp đính kèm"
                  accept="image/*,.doc,.docx,.pdf,.xls,.xlsx,.ppt"
                  fileList={attachmentList}
                  onChange={this.handleChangeAttachment}
                  uploadFile={this.uploadAttachment}
                  disabled={loadingSave}
                  loading={uploading}
                  required={isAttachmentRequired}
                  requiredMsg="Vui lòng chọn tệp đính kèm"
                />
                <DropdownField
                  fieldName="questionRank"
                  label="Cấp độ câu hỏi"
                  required
                  options={QuestionRank}
                  disabled={loadingSave}
                  requiredMsg="Vui lòng nhập cấp độ câu hỏi"
                />
           </Col>
        </Row>
        <Row gutter={16}>
              <Col span={12}>
              <TextField
                fieldName="questionCompetencesName"
                label="Năng lực"
                required
                disabled={true}
                requiredMsg="Vui lòng chọn năng lực"
                />
                {
                  !isEditing ? 
                  <DropdownField
            fieldName="questionKind"
            label="Loại"
            required
            options={commonQuestionKind}
            disabled={loadingSave || isEditing}
            onChange={this.handleChangeQuestionKind}
            /> : null
                }
          </Col>
          <Col span={12}>
                <TextField
                  fieldName="questionName"
                  label="Nội dung câu hỏi"
                  required
                  requiredMsg="Vui lòng nhập nội dung câu hỏi"
                  type="textarea"
                  disabled={loadingSave}
                  style={{height: isEditing ? 80 : 118}}
                />
              </Col>
        </Row>
        <Row gutter={16}>
          {
            isEditing ? <>
            <Col span={12}>
            <DropdownField
            fieldName="questionKind"
            label="Loại"
            required
            options={commonQuestionKind}
            disabled={loadingSave || isEditing}
            onChange={this.handleChangeQuestionKind}
            />
          </Col> 
            <Col span={12}>
              <DropdownField
              fieldName="status"
              label="Trạng thái"
              required
              options={commonStatus}
              disabled={loadingSave}
              />
            </Col> 
            </>
            : null
          }
        </Row>
        <Row gutter={16} style={{minHeight: 150}} >
          <Col span={24}>
            <BaseQuestion 
            kind={questionKind} 
            disabled={loadingSave} 
            questionContent={this.otherData[questionKind - 1] || []}
            handleChangeOtherData={this.handleChangeOtherDataFromThis}
            fieldName="questionContent"
            isEditing={isEditing}
            uploadFile={uploadFile}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default QuestionForm;
