import reduxHelper from '../utils/redux';

export const reduxUtil = reduxHelper('COMPETENCES');
const { defineAction, createActionWithLoading, createAction } = reduxUtil;

export const actionTypes = {
    GET_COMPETENCES: defineAction('GET_COMPETENCES'),
    CREATE_COMPETENCES: defineAction('CREATE_COMPETENCES'),
    DELETE_COMPETENCES: defineAction('DELETE_COMPETENCES'),
    UPDATE_COMPETENCES: defineAction('UPDATE_COMPETENCES'),
    GET_COMPETENCES_LIST: defineAction('GET_COMPETENCES_LIST'),
    GET_SURVEYPROFILE_AUTOCOMPLETE_LIST: defineAction('GET_SURVEYPROFILE_AUTOCOMPLETE_LIST'),
    GET_WORKFIELD_AUTOCOMPLETE_LIST: defineAction('GET_WORKFIELD_AUTOCOMPLETE_LIST'),

}

export const actions = {
    getCompetences: createAction(actionTypes.GET_COMPETENCES),
    createCompetences: createAction(actionTypes.CREATE_COMPETENCES),
    deleteCompetences: createActionWithLoading(actionTypes.DELETE_COMPETENCES),
    updateCompetences: createAction(actionTypes.UPDATE_COMPETENCES),
    getCompetencesList: createActionWithLoading(actionTypes.GET_COMPETENCES_LIST),
    getSurveyProfileAutoCompleteList: createActionWithLoading(actionTypes.GET_SURVEYPROFILE_AUTOCOMPLETE_LIST),
    getWorkFieldAutoCompleteList: createActionWithLoading(actionTypes.GET_WORKFIELD_AUTOCOMPLETE_LIST),
}