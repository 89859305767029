import React from 'react';

import { Select } from 'antd';

import BaseField from '../../compoments/common/entryForm/BaseField';
import { FieldTypes } from '../../constants/formConfig';

class CustomDropdownField extends BaseField {
    constructor(props) {
        super(props);
        this.fieldType = FieldTypes.SELECT;
    }
    render() {
        const {
            label,
            loading,
            disabled,
            mode,
            tagRender,
            options,
            optionValue,
            optionLabel,
            optionOther,
            labelInValue,
            onSelect,
            onChange,
            defaultValue,
            initialvalues,
            style,
            value,
        } = this.props;

        let optionValueKey = optionValue || 'value';
        let optionLabelKey = optionLabel || 'label';
        const optionOtherKey = optionOther || 'other';

        return (

                <Select
                    loading={loading}
                    placeholder={this.getPlaceHolder()}
                    mode={mode}
                    disabled={disabled}
                    onSelect={onSelect}
                    onChange={onChange}
                    tagRender={tagRender}
                    initialvalues={initialvalues}
                    defaultValue={defaultValue}
                    style={style}
                    value={value}
                    label={label}
                >
                    {
                        options
                        ?
                        options.map(item =>
                        <Select.Option key={item[optionValueKey]} value={item[optionValueKey]} other={item[optionOtherKey]} style={style}>{item[optionLabelKey]}{labelInValue?` (${item[optionValueKey]})` : null }</Select.Option>
                        )
                        :
                        null
                    }
                </Select>
        )
    }
}

export default CustomDropdownField;
