import React, { Component } from 'react'

import {Checkbox, Form, Col, Row, Button, Card, Input } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import BaseTable from "../../../compoments/common/table/BaseTable";

export default class BrowserChooseAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            answerList: Array.isArray(props.questionContent) 
            && props.questionContent.length > 0
            && props.questionContent[0].url
            && props.questionContent[0].question
             ? [...props.questionContent] : [{
                url: undefined,
                question: []
            }],
            currentFocus: undefined,
         };
        this.columns = [
            { 
                title: "Nội dung", 
                dataIndex: "content",
                render: (content, record, i) => (
                    <span>
                          <Form.Item 
                          name={[this.props.fieldName, 0, 'question', i, 'content']} 
                          style={{margin: "8px 0"}}
                          rules={[
                            { required: true, message: 'Vui lòng nhập câu trả lời'}
                        ]}>
                            <Input required
                             onChange={(e)=>{this.handleChangeInput(e , i)}}
                            />
                          </Form.Item>
                    </span>
                )
            },
            { 
              title: "Đáp án", 
              align: "right",
              width: "100px",
              dataIndex: "rightAnswer",
              render: (rightAnswer, record, i) => {
                return (
                    <Form.Item 
                    style={{margin: "8px 0"}}
                    name={[this.props.fieldName, 0, 'question' ,i, 'rightAnswer']} 
                    valuePropName="checked" >
                    <Checkbox 
                        onChange={(e)=>{this.handleChangeCheckbox(e , i)}}
                        />
                    </Form.Item>
                )
              }
                    
            },
            {
                width: "100px",
                align: "right",
                render: (text, record, index)=>(
                    <Button type="link" onClick={() => this.handleDelete(index) } className="no-padding">
                                <DeleteOutlined/>
                    </Button>
                )
            }
        ];
    }

    handleChangeCheckbox = (e, i) => {
        const { answerList } = this.state;
        let newAnswerList = [...answerList];
        if(newAnswerList[0] 
            && newAnswerList[0].question 
            && newAnswerList[0].question[i]
            && !newAnswerList[0].question[i].rightAnswer){
            newAnswerList[0].question = newAnswerList[0].question.map(e=>{
                e.rightAnswer = false;
                return e;
            })
            newAnswerList[0].question[i].rightAnswer = true;
        }
        this.handleChangeQuestionContent(newAnswerList);
    }

    handleChangeInput = (e, i) => {
        const { answerList } = this.state;
        const newAnswerList = [...answerList];
        newAnswerList[0].question[i].content = e.target.value;
        this.handleChangeQuestionContent(newAnswerList);
    }

    handleAddQuest = () => {
        const { answerList } = this.state;
        const newAnswerList = [...answerList];

        if(newAnswerList[0] && newAnswerList[0].question)
            newAnswerList[0].question = [
                ...newAnswerList[0].question, {
                content: "",
                rightAnswer: newAnswerList[0].question.length <= 0 ? true : false
            }];
        this.setState({
            answerList: newAnswerList
        })
        this.handleChangeQuestionContent(newAnswerList);
    }
    handleDelete = (i) => {
        const { answerList } = this.state;
        const newAnswerList = [...answerList];
        if(newAnswerList[0] && newAnswerList[0].question){
            newAnswerList[0].question = newAnswerList[0].question.filter((item, index) => index !== i);
            if(newAnswerList[0].question.length > 0 && !newAnswerList[0].question.find(e=>e.rightAnswer===true))newAnswerList[0].question[0].rightAnswer = true;
        }

        this.setState({
            answerList: newAnswerList,
        });
        this.handleChangeQuestionContent(newAnswerList);
    }

    handleChangeQuestionContent = (newAnswerList) => {
        const { handleChangeOtherData } = this.props;
        handleChangeOtherData(newAnswerList);
    }
    handleChangeUrl = (e) => {
        const { answerList } = this.state;
        const newAnswerList = [...answerList];
        newAnswerList[0].url = e.target.value;
        this.handleChangeQuestionContent(newAnswerList);
    }
    render() {
        const { disabled, fieldName } = this.props;
        const { answerList } = this.state;
        return (
            <Card size="small"  style={{ width: '100%', marginBottom: '4px' }}>
                <Row gutter={16}>
                    <Col span={24}>
                                <Form.Item
                                    name={[fieldName, 0, "url"]}
                                    label="Đường dẫn website"
                                    disabled={disabled}
                                    required
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập đường dẫn website'}
                                    ]}
                                    
                                >
                                    <Input onChange={(e)=> {
                                    this.handleChangeUrl(e);
                                }}/>
                                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        Danh sách câu trả lời
                    </Col>
                    <Col span={12}>
                        <div style={{ textAlign: "right"}}>
                            <Button  type="primary" onClick={() => {this.handleAddQuest()}}>
                            <PlusOutlined /> Thêm câu hỏi
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                    <Form.Item name={[fieldName, 0, "question"]}>
                        <BaseTable
                        columns={this.columns}
                        rowKey={(record, i) => i}
                        dataSource={answerList[0].question}
                        loading={disabled}
                        pagination={false}
                        />
                    </Form.Item>
                    </Col>
                </Row>
            </Card>
        )
    }
}
