import React from "react";
import { Form, Col, Row } from "antd";

import BasicForm from "../common/entryForm/BasicForm";
import TextField from "../common/entryForm/TextField";
import { commonStatus } from "../../constants/masterData";
import NumericField from "../common/entryForm/NumericField";
import DropdownField from "../common/entryForm/DropdownField";
import CropImageFiled from "../common/entryForm/CropImageFiled";
import UploadImageField from "../common/entryForm/UploadImageField";

import RichTextField from "../../compoments/common/entryForm/RichTextField";

import {
    AppConstants,
    UploadFileTypes,
    STATUS_ACTIVE,
  } from "../../constants";
  import Utils from "../../utils";
  import { showErrorMessage } from "../../services/notifyService";


class NewsForm extends BasicForm {
    constructor(props) {
        super(props);
        this.state = {
          avatar: props.dataDetail.newAvatar
            ? `${AppConstants.contentRootUrl}/${props.dataDetail.newAvatar}`
            : "",
          uploading: false,
        };
    }

    getInitialFormValues = () => {
        const { isEditing, dataDetail, categoryOptions } = this.props;
        if (!isEditing) {
          return {
            status: STATUS_ACTIVE,
            categoryId: categoryOptions[0] && categoryOptions[0].value,
            newOrdering: 0,
          };
        }
        return dataDetail;
      };

    uploadFileAvatar = (file, onSuccess) => {
        const { uploadFile } = this.props;
        this.setState({ uploading: true });
        uploadFile({
            params: { fileObjects: { file }, type: UploadFileTypes.AVATAR },
            onCompleted: (result) => {
            this.setFieldValue("newAvatar", result.data.filePath);
            this.setState({
              avatar: `${AppConstants.contentRootUrl}/${result.data.filePath}`
            });
            this.setState({ uploading: false });
            //onSuccess();
            },
            onError: (err) => {
            if (err && err.message) {
                showErrorMessage(err.message);
                this.setState({ uploading: false });
            }
            },
        });
    };
  render() {
    console.log(this.getFieldValue('newAvatar'))
    const { formId, categoryOptions, loadingSave } = this.props;
    const { uploading, avatar } = this.state;
    return (
      <Form
        id={formId}
        ref={this.formRef}
        layout="vertical"
        onFinish={this.handleSubmit}
        initialValues={this.getInitialFormValues()}
      >
        <Row gutter={16}>
            <Col span={12}>
                <UploadImageField
                    fieldName="newAvatar"
                    loading={uploading}
                    label="Ảnh đại diện"
                    imageUrl={avatar}
                    onChange={this.handleChangeAvatar}
                    uploadFile={this.uploadFileAvatar}
                    required
                    requiredMsg="Hãy nhập ảnh đại diện"
                    disabled={loadingSave}
                />
            </Col>
            <Col span={12}>
            <TextField
                fieldName="newTitle"
                label="Tựa đề"
                required
                disabled={loadingSave}
            />
            </Col>
        </Row>
    
        <Row gutter={16}>
            <Col span={12}>
                <DropdownField
                fieldName="categoryId"
                label="Thể loại"
                required
                options={categoryOptions}
                disabled={loadingSave}
                />
            </Col>

              <Col span={12}>
                  <DropdownField
                  fieldName="status"
                  label="Trạng thái"
                  required
                  options={commonStatus}
                  disabled={loadingSave}
                  />
              </Col>

        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <NumericField
                width="100%"
                fieldName="newOrdering"
                label="Thứ tự"
                min={0}
                required
                disabled={loadingSave}
            />
          </Col>
          <Col span={12}>
                <TextField
                    fieldName="newDescription"
                    label="Mô tả"
                    type="textarea"
                    style={{height: "150px"}}
                    disabled={loadingSave}
                />
            </Col>
        </Row>
        
        <Row gutter={16}>
            <Col span={24}>
                <RichTextField label="Nội dung" fieldName="newContent" disabled={loadingSave}/>
            </Col>
        </Row>
      </Form>
    );
  }
}

export default NewsForm;
