import {
    STATUS_ACTIVE,
    STATUS_LOCK,
    GroupPermissonTypes,
} from './';

import React from 'react';
import ChooseAnswer from '../compoments/question/kind/ChooseAnswer';
import FillAnswer from '../compoments/question/kind/FillAnswer';
import AttachFillAnswer from '../compoments/question/kind/AttachFillAnswer';
import AttachChooseAnswer from '../compoments/question/kind/AttachChooseAnswer';
import SetOfQuestions from '../compoments/question/kind/SetOfQuestions';
import BrowserFillAnswer from '../compoments/question/kind/BrowserFillAnswer';
import BrowserChooseAnswer from '../compoments/question/kind/BrowserChooseAnswer';
import StepByStepOnImages from '../compoments/question/kind/StepByStepOnImages';
import EmailQuestion from '../compoments/question/kind/EmailQuestion';
export const groupPermissionTypes = [
    { value: GroupPermissonTypes.ADMIN, label: 'Quản trị viên' },
    { value: GroupPermissonTypes.CUSTOMER, label: 'Khách hàng'}
]

export const commonStatus = [
    { value: STATUS_ACTIVE, label: 'Kích hoạt', color: 'green' },
    // { value: STATUS_INACTIVE, label: 'Inactive', color: 'warning' },
    { value: STATUS_LOCK, label: 'Đang khóa', color: 'red' },
]

export const commonLanguages = [
    { value: 'vi', label: 'Việt Nam'},
    { value: 'en', label: 'English'},
    { value: 'de', label: 'German'},
]

export const commonKinds = [
    { value: 1, label: 'Tin tức'},
    { value: 2, label: 'Dịch vụ'},
]

export const commonSex = [
    { value: 0, label: 'Nữ' },
    { value: 1, label: 'Nam' }
]

export const commonQuestionKind = [
    { 
        value: 1, 
        label: 'Chọn đáp án đúng', 
        isAttachmentRequired: false,
        Component: (props) => <div className="component"><ChooseAnswer {...props}/></div>
    },
    { 
        value: 2, 
        label: 'Điền câu trả lời', 
        isAttachmentRequired: false,
        Component: (props) => <div className="component"><FillAnswer {...props}/></div>
    },
    { 
        value: 3, 
        label: 'Đọc tài liệu điền câu trả lời', 
        isAttachmentRequired: true,
        Component: (props) => <div className="component"><AttachFillAnswer {...props}/></div>
    },
    { 
        value: 4, 
        label: 'Đọc tài liệu chọn đáp án đúng', 
        isAttachmentRequired: true,
        Component: (props) => <div className="component"><AttachChooseAnswer {...props}/></div>
    },
    { 
        value: 5, 
        label: 'Tập hợp câu hỏi', 
        isAttachmentRequired: false,
        Component: (props) => <div className="component"><SetOfQuestions {...props}/></div>
    },
    { 
        value: 6, 
        label: 'Duyệt web trả lời câu hỏi', 
        isAttachmentRequired: false,
        Component: (props) => <div className="component"><BrowserFillAnswer {...props}/></div>
    },
    { 
        value: 7, 
        label: 'Duyệt web chọn đáp án đúng', 
        isAttachmentRequired: false,
        Component: (props) => <div className="component"><BrowserChooseAnswer {...props}/></div>
    },
    { 
        value: 8, 
        label: 'Bài test bằng hình ảnh', 
        isAttachmentRequired: false,
        Component: (props) => <div className="component"><StepByStepOnImages {...props}/></div>
    },
    { 
        value: 9, 
        label: 'Đọc và điền thông tin vào email', 
        isAttachmentRequired: false,
        Component: (props) => <div className="component"><EmailQuestion {...props}/></div>
    },
]

export const QuestionRank = [
    {
        value: 1,
        label: 'Câu hỏi thái độ',
        score: 0,
    },
    {
        value: 2,
        label: 'Câu hỏi kiến thức mức cơ bản',
        score: 1,
    },
    {
        value: 3,
        label: 'Câu hỏi kiến thức mức trung bình',
        score: 2,
    },
    {
        value: 4,
        label: 'Câu hỏi kiến thức mức nâng cao',
        score: 3,
    },
    {
        value: 5,
        label: 'Câu hỏi kỹ năng mức trung bình',
        score: 4,
    },
]

const GENDER_MALE = 1
const GENDER_FEMALE = 2
const GENDER_OTHER = 3

export const genders = [
    { value: GENDER_MALE, label: 'Nam' },
    { value: GENDER_FEMALE, label: 'Nữ' },
    { value: GENDER_OTHER, label: 'Khác' },
]