import React from 'react';
import { connect } from 'react-redux';
import { Button, } from 'antd';
import { PlusOutlined,} from '@ant-design/icons';

import ListBasePage from '../ListBasePage';
import CategoryForm from '../../compoments/category/CategoryForm';
import BaseTable from '../../compoments/common/table/BaseTable';
import BasicModal from '../../compoments/common/modal/BasicModal';

import { actions } from '../../actions';
import { CategoryKinds } from "../../constants";

class CategoryJobListPage extends ListBasePage {
    initialSearch() {
        return { name: "",};
    }

    constructor(props) {
        super(props);
        this.objectName = 'danh mục nghề nghiệp';
        this.breadcrumbs = [{name: 'Danh mục nghề nghiệp'}];
        this.columns = [
            this.renderIdColumn(),
            { title: 'Tên', dataIndex: 'categoryName'},
            this.renderActionColumn()
        ];
        this.actionColumns = {
            isEdit: true,
            isDelete: true,
            isChangeStatus: false
        }
    }

    getSearchFields() {
        return [
            { 
                key: "name", 
                seachPlaceholder: 'Tên danh mục nghề nghiệp', 
                initialValue: this.search.name,
            },
        ];
    }

    prepareCreateData(values) {
        return {
            kind: CategoryKinds.CATEGORY_KIND_JOB,
            ...values,
        };
    }
    
    prepareUpdateData(data) {
        return {
            ...data,
            categoryId: this.dataDetail.id,
        };
    }


    getDataDetailMapping(data) {
        return {
            ...data,
            parentId: this.dataDetail.id,
        }
    }

    render() {
        const { dataList, loading, } = this.props;
        const { isShowModifiedModal, isShowModifiedLoading } = this.state;
        this.pagination.total = dataList.totalElements || 0;
        this.pagination.pageSize = 30;

        let category = [];
        let j = 0;
        if (dataList.data)
            for (let i = 0; i <this.pagination.total;i++)
                if (dataList.data[i] && !dataList.data[i].parentId)
                    category[j++] = dataList.data[i];

        return (
            <div>
                {this.renderSearchForm()}
                <div className="action-bar">    
                    {this.renderCreateNewButton((
                        <Button
                        type="primary"
                        onClick={() => this.onShowModifiedModal(false)}
                    >
                        <PlusOutlined /> Thêm nghề nghiệp
                    </Button>
                    ))}
                </div>
                <BaseTable
                    loading={loading}
                    columns={this.columns}
                    rowKey={record => record.id}
                    dataSource={category}
                    pagination={this.pagination}
                    onChange={this.handleTableChange}
                />
                <BasicModal
                    visible={isShowModifiedModal}
                    isEditing={this.isEditing}
                    objectName={this.objectName}
                    loading={isShowModifiedLoading}
                    onOk={this.onOkModal}
                    onCancel={this.onCancelModal}
                    >
                        <CategoryForm
                            isEditing={this.isEditing}
                            dataDetail={this.isEditing ?{...this.dataDetail} : {}}
                            loadingSave={isShowModifiedLoading}
                        />
                </BasicModal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.category.categoryListLoading,
    dataList: state.category.categoryListData || {},
    parent: state.category.parent,
})

const mapDispatchToProps = dispatch => ({
    getDataList: (payload) => dispatch(actions.getJobCategoryList(payload)),
    getDataById: (payload) => dispatch(actions.getCategory(payload)),
    createData: (payload) => dispatch(actions.createCategory(payload)),
    updateData: (payload) => dispatch(actions.updateCategory(payload)),
    deleteData: (payload) => dispatch(actions.deleteCategory(payload)),

})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryJobListPage);