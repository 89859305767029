import { call, put, takeLatest } from 'redux-saga/effects';

import { sendRequest } from '../services/apiService';
import { actionTypes, reduxUtil } from '../actions/question';
import apiConfig from '../constants/apiConfig';
import { handleApiResponse } from '../utils/apiHelper';

const { defineActionLoading, defineActionSuccess, defineActionFailed } = reduxUtil;

const {
    GET_QUESTION_LIST,
    DELETE_QUESTION,
    GET_COMBOBOX_COMPETENCES,
} = actionTypes;


function* getQuestionList({ payload: { params } }) {

    const apiParams = apiConfig.question.getQuestionList;
    const searchParams = { page: params.page, size: params.size };
    if(params.search) {
        if(params.search.competencesId) {
            searchParams.competencesId = params.search.competencesId
        }
        if(params.search.questName) {
            searchParams.questName = params.search.questName
        }
        if(params.search.status) {
            searchParams.status = params.search.status
        }
        if(params.search.competencesId) {
            searchParams.competencesId = params.search.competencesId
        }
        
    }
    try {
        const result = yield call(sendRequest, apiParams, searchParams);
        yield put({
            type: defineActionSuccess(GET_QUESTION_LIST),
            questionData: result.responseData && result.responseData.data
        });
    }
    catch(error) {
        yield put({ type: defineActionFailed(GET_QUESTION_LIST) });
    }
}

function* createQuestion({payload: { params, onCompleted, onError }}){
    try {
        const apiParams = apiConfig.question.create;
        const result = yield call(sendRequest, apiParams, params);
        handleApiResponse(result, onCompleted, onError);
    }
    catch(error) {
        onError(error);
    }
}

function* getQuestionById({ payload: { params, onCompleted, onError } }) {
    try {
        const apiParams = {
            ...apiConfig.question.getById,
            path: `${apiConfig.question.getById.path}/${params.id}`
        }
        const result = yield call(sendRequest, apiParams);
        handleApiResponse(result, onCompleted, onError);
    }
    catch(error) {
        onError(error);
    }
}

function* updateQuestion({ payload: { params, onCompleted, onError } }) {
    try {
        const apiParams = apiConfig.question.update;
        const result = yield call(sendRequest, apiParams, params);
        handleApiResponse(result, onCompleted, onError);
    }
    catch(error) {
        onError(error);
    }
}

function* deleteQuestion({ payload: { params, onCompleted, onError } }) {
    try {
        const apiParams = {
            ...apiConfig.question.delete,
            path: `${apiConfig.question.delete.path}/${params.id}`
        }
        const result = yield call(sendRequest, apiParams);
        handleApiResponse(result, onCompleted, onError);

        const { success, responseData } = result;
        if(!success || !responseData.result)
            yield put({ type: defineActionFailed(DELETE_QUESTION) });
    }
    catch(error) {
        yield put({ type: defineActionFailed(DELETE_QUESTION) });
        onError(error);
    }
}

function* getComboboxCompetences() {

    const apiParams = apiConfig.question.getComboboxCompetences;
    
    try {
        const result = yield call(sendRequest, apiParams, {});
        yield put({
            type: defineActionSuccess(GET_COMBOBOX_COMPETENCES),
            comboboxCompetencesData: result.responseData && result.responseData.data
        });
    }
    catch(error) {
        yield put({ type: defineActionFailed(GET_COMBOBOX_COMPETENCES) });
    }
}

const sagas = [
    takeLatest(defineActionLoading(GET_QUESTION_LIST), getQuestionList),
    takeLatest(actionTypes.CREATE_QUESTION, createQuestion),
    takeLatest(actionTypes.GET_QUESTION_BY_ID, getQuestionById),
    takeLatest(actionTypes.UPDATE_QUESTION, updateQuestion),
    takeLatest(defineActionLoading(DELETE_QUESTION), deleteQuestion),
    takeLatest(GET_COMBOBOX_COMPETENCES, getComboboxCompetences),
]

export default sagas;