import reduxHelper from '../utils/redux';

export const reduxUtil = reduxHelper('EXAM');

const { defineAction, createActionWithLoading, } = reduxUtil;

export const actionTypes = {
    DETAILS_ADMIN: defineAction('DETAILS_ADMIN'),
    LIST_ADMIN: defineAction('LIST_ADMIN'),
}

export const actions = {
    getDetailsAdminList: createActionWithLoading(actionTypes.DETAILS_ADMIN),
    getListAdminList: createActionWithLoading(actionTypes.LIST_ADMIN)
}