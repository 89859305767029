import React from "react";
import { Form, Col, Row } from "antd";

import BasicForm from "../common/entryForm/BasicForm";
import TextField from "../common/entryForm/TextField";
import FieldSet from "../common/elements/FieldSet";
import DropdownField from "../common/entryForm/DropdownField";
import CropImageFiled from "../common/entryForm/CropImageFiled";
import DatePickerField from "../common/entryForm/DatePickerField";
import { genders } from "../../constants/masterData";
import { convertStringToDateTime, convertDateTimeToString } from "../../utils/datetimeHelper";
import {
  AppConstants,
  UploadFileTypes,
} from "../../constants";
import Utils from "../../utils";
import { showErrorMessage } from "../../services/notifyService";
import { convertStringToDateTimeString } from "../../utils/datetimeHelper";

class CustomerForm extends BasicForm {
  constructor(props) {
    super(props);
    this.state = {
      avatar: props.dataDetail.avatarPath
        ? `${AppConstants.contentRootUrl}/${props.dataDetail.avatarPath}`
        : "",
    }
  }

  getInitialFormValues = () => {
    const { dataDetail } = this.props;
    return {
        ...dataDetail,
        birthday: convertStringToDateTimeString(dataDetail.birthday, 'DD/MM/YYYY hh:mm:ss', 'DD/MM/YYYY'),
    };
  };

  handleSubmit(formValues) { 
    const { onSubmit } = this.props;
    onSubmit({
        status: formValues.status,
    });
  }

  render() {
    const { formId, dataDetail, commonStatus, loadingSave } = this.props;
    const { avatar } = this.state;
    return (
      <Form
        id={formId}
        ref={this.formRef}
        layout="vertical"
        onFinish={this.handleSubmit}
        initialValues={this.getInitialFormValues()}
        className="readonly-form"
      >
        <FieldSet title="Thông tin người dùng" className="bold-fieldset custom-fieldset">
          <Row gutter={16}>
              <Col span={12}>
                  <CropImageFiled
                      fieldName="avatarPath"
                      label="Ảnh đại diện"
                      imageUrl={avatar}
                      disabled
                  />
              </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
                <TextField
                  fieldName="fullName"
                  label="Họ và tên"
                  disabled
                />
              </Col>
              <Col span={12}>
                <TextField
                  fieldName="birthday"
                  label="Ngày sinh"
                  disabled
                />
              </Col>
          </Row>
          <Row gutter={16}>
          <Col span={12}>
              <TextField
                type="number"
                fieldName="phone"
                label="Số điện thoại"
                disabled
              />
            </Col>
            <Col span={12}>
              <TextField fieldName="email" label="E-mail" type="email" disabled/>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <TextField
                fieldName="username"
                label="Tên đăng nhập"
                disabled
              />
            </Col>
            <Col span={12}>
              <DropdownField
                fieldName="sex"
                label="Giới tính"
                options={genders}
                disabled
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <DropdownField
                fieldName="status"
                label="Trạng thái"
                required
                options={commonStatus}
                disabled={loadingSave}
              />
            </Col>
          </Row>
        </FieldSet>
        <FieldSet title="Công việc" className="bold-fieldset custom-fieldset margin-top-fieldset">
          <Row gutter={16}>
              <Col span={12}>
                  <TextField
                    fieldName={["university", "categoryName"]}
                    label="Trường"
                    disabled
                  />
              </Col>
              <Col span={12}>
                  <TextField
                    fieldName={["universityDept", "categoryName"]}
                    label="Khoa"
                    disabled
                  />
              </Col>
              <Col span={12} hidden>
                  <TextField
                    fieldName={["university", "categoryId"]}
                    label="Trường"
                    disabled
                  />
              </Col>
              <Col span={12} hidden>
                  <TextField
                    fieldName={["universityDept", "categoryId"]}
                    label="Khoa"
                    disabled
                  />
              </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
                  <TextField
                    fieldName={["job", "categoryName"]}
                    label="Nghề nghiệp"
                    disabled
                  />
              </Col>
              <Col span={12} hidden>
                  <TextField
                    fieldName={["job", "categoryId"]}
                    label="Nghề nghiệp"
                    disabled
                  />
              </Col>
          </Row>
        </FieldSet>
        {/* <FieldSet title="Địa chỉ" className="bold-fieldset custom-fieldset margin-top-fieldset">
          <Row gutter={16}>
              <Col span={12}>
                  <DropdownField
                    fieldName={["provinceDto", "provinceId"]}
                    label="Tinh thành"
                    required
                    options={this.state.provinces || []}
                    onChange={(id)=>{this.handleChangeProvince('provinceCombobox', id)}}
                    disabled={loadingSave}
                  />
              </Col>
              <Col span={12}>
                <DropdownField
                  disabled={this.state.districts.length > 0 && !loadingSave ? false : true}
                  fieldName={["districtDto", "provinceId"]}
                  label="Quận/Huyện"
                  required
                  options={this.state.districts || []}
                  onChange={(id)=>{this.handleChangeProvince('districtCombobox', id)}}
                />
              </Col>
              
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <DropdownField
                disabled={this.state.wards.length > 0 && !loadingSave ? false : true}
                fieldName={["wardDto", "provinceId"]}
                label="Phường/Xã"
                required
                options={this.state.wards || []}
                onChange={(id)=>{this.handleChangeProvince('wardCombobox', id)}}
              />
            </Col>
        </Row>
        </FieldSet> */}
      </Form>
    );
  }
}

export default CustomerForm;
