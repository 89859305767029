import React from 'react';

import { UsergroupAddOutlined, ControlOutlined, FileTextOutlined, UserOutlined, QuestionOutlined } from '@ant-design/icons';
import { sitePathConfig } from '../constants/sitePathConfig';
const navMenuConfig = [
    {
        label: 'Quản lý tài khoản',
        icon: <UsergroupAddOutlined />,
        children: [
            {
                label: 'Quản trị viên',
                ...sitePathConfig.admin
            },
        ]
    },
    {
        label: 'Hệ thống',
        icon: <ControlOutlined />,
        children: [
            {
                label: 'Cài Đặt',
                ...sitePathConfig.setting
            },
            {
                label: 'Quyền',
                ...sitePathConfig.groupPermission
            },
            {
                label: 'Tỉnh thành',
                ...sitePathConfig.province
            },
        ]
    },
    {
        label: 'Quản lý câu hỏi',
        icon: <QuestionOutlined />,
        children: [
            {
                label: 'Quản lý lĩnh vực',
                ...sitePathConfig.area
            },
            {
                label: 'Cấu hình nhóm',
                ...sitePathConfig.surveyProfile
            },
        ]
    },
    {
        label: 'Tin tức',
        icon: <FileTextOutlined />,
        children: [
            {
                label: 'Tin tức',
                ...sitePathConfig.news
            },
            {
                label: 'Thể loại',
                ...sitePathConfig.category
            },
            {
                label: 'Danh mục trường',
                ...sitePathConfig.categoryuniversity
            },
            {
                label: 'Danh mục nghề nghiệp',
                ...sitePathConfig.categoryjob
            },
        ]
    },
    {
        label: 'Bài kiểm tra',
        icon: <UserOutlined />,
        children: [
            {
                label: 'Tất cả',
                ...sitePathConfig.exam
            },
            {
                label: 'Theo người dùng',
                ...sitePathConfig.customer
            },
        ]
    },
]

export { navMenuConfig };
