import React, { Component } from 'react'

import {Checkbox, Form, Col, Row, Card, Input } from "antd";

import BaseTable from "../../../compoments/common/table/BaseTable";
import {
    UploadFileTypes,
} from "../../../constants";
import { showErrorMessage } from "../../../services/notifyService";

const { TextArea } = Input;

export default class EmailQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            emailContent: Array.isArray(props.questionContent) && props.questionContent.length>0 ? [...props.questionContent] : 
            [
                {
                    content:"From",
                    answer:"",
                    require: false,
                },
                {
                    content:"To",
                    answer:"",
                    require: false,
                },
                {
                    content:"Cc",
                    answer:"",
                    require: false,
                },
                {
                    content:"Bcc",
                    answer:"",
                    require: false,
                },
                {
                    content:"Subject",
                    answer:"",
                    require: false,
                },
                {
                    content:"Attachment",
                    answer:false,
                    require: false,
                },
                {
                    content:"Message",
                    answer:"",
                    require: false,
                },
            ],
        };
        this.columns = [
            { 
                title: "Nội dung", 
                align: "right",
                width: "100px",
                dataIndex: "content",
                render: (content, record, i) => {
                    return (
                        <div>{content}</div>
                    )
                }
                      
              },
            { 
                title: "Câu hỏi", 
                dataIndex: "answer",
                render: (answer, record, i) => (
                    <span>
                          <Form.Item
                            name={[this.props.fieldName, i, 'answer']}  
                            style={{margin: "8px 0"}}
                            
                            >
                            {
                                record.content==="Message"?
                                    <TextArea onChange={(e)=> {
                                        this.handleChangeInput(e,i);
                                    }}
                                    required ={record.require}
                                    />
                                :
                                record.content==="Attachment"?<div/>
                                :
                                    <Input 
                                        onChange={(e)=>{this.handleChangeInput(e , i)}}
                                        required ={record.require}
                                    />
                            }
                          </Form.Item>
                    </span>
                )
            },
            { 
                title: "Bắt buộc", 
                align: "center",
                width: "80px",
                dataIndex: "require",
                render: (require, record, i) => (
                    <span>
                          <Checkbox defaultChecked={require} onChange={(e)=>{this.handleChangeRequire(e , i)}}></Checkbox>
                    </span>
                )
            },
           
        ];
    }

    handleChangeInput = (e, i) => {
        const { emailContent } = this.state;
        const newEmailContent = [...emailContent];
        if (e.target.type==="checkbox") newEmailContent[i].answer = e.target.checked;
        else
        newEmailContent[i].answer = e.target.value;
        this.handleChangeQuestionContent(newEmailContent);
    }
    handleChangeRequire = (e, i) => {
        const { emailContent } = this.state;
        const newEmailContent = [...emailContent];
        if (newEmailContent[i].content==="Attachment") newEmailContent[i].answer = e.target.checked;
        if (!e.target.checked) newEmailContent[i].require = false;
        else newEmailContent[i].require = e.target.checked;
        
        this.handleChangeQuestionContent(newEmailContent);
    }
    handleChangeQuestionContent = (newAnswerList) => {
        const { handleChangeOtherData } = this.props;
        handleChangeOtherData(newAnswerList);
    }

    uploadAttachment = (file, onSuccess) => {
        const { uploadFile } = this.props;
        uploadFile({
            params: { fileObjects: { file }, type: UploadFileTypes.AVATAR },
            onCompleted: (result) => {                
            onSuccess();
            },
            onError: (err) => {
            if (err && err.message) {
                showErrorMessage(err.message);
            }
            },
        });
      };
    render() {
        const { disabled, fieldName } = this.props;
        const { emailContent } = this.state;
        return (
            <Card size="small"  style={{ width: '100%', marginBottom: '4px' }}>
            <Row>
                <Col span={12}>
                    Danh sách câu trả lời
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name={fieldName}
                            required
                            rules={[
                                { required: true, message: 'Vui lòng nhập câu trả lời'}
                            ]}
                        >
                            <BaseTable
                            columns={this.columns}
                            rowKey={(record, i) => i}
                            dataSource={emailContent}
                            loading={disabled}
                            pagination={false}
                            />
                           
                    </Form.Item>
                </Col>
            </Row>
            </Card>
        )
    }
}
