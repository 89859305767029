import React, { Component } from 'react'

import {Checkbox, Form, Col, Row, Button, Card, Input, } from "antd";
import { PlusOutlined, DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import BaseTable from "../../../compoments/common/table/BaseTable";
import TextArea from 'antd/lib/input/TextArea';
const { Search } = Input;

export default class SetOfQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            questions: props.questionContent || {},
            columns: [],
            isColumnEditing: false,
            ColumnEditting:0,
            checkbox:true,
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.onCheckchange = this.onCheckchange.bind(this);

    };

    
    componentDidMount()
    {
        const {questionContent} = this.props;
        const columns = [{ 
            title: "Câu hỏi", 
            align: "left",
            dataIndex: "question",
            render: (question, record, i) => (
                <span>
                      <Form.Item style={{height:70}}  required 
                        name={['questionContent','questions',i, 'question',]}                       
                      >
                            <TextArea style={{width:300, maxHeight:80 }} onChange={(value)=>this.handleChangeInput(value, i)} required/>
                      </Form.Item>
                </span>
            )
        },
        ];
        questionContent && questionContent.answerList && questionContent.answerList.forEach((e,index,i)=>{
            columns.push(
                { 
                    title: 
                        <Form.Item name={'questionContent','answerList',index}>
                            <div onClick={(e)=>this.handleRenameColumn(e,index)}>{this.state.questions.answerList[index]}</div>
                        </Form.Item>, 
                    align: "center",
                    dataIndex: "answerList",
                    render: (rightAnswer, record, i, index) => {
                        let columnindex=questionContent.answerList.indexOf(e);

                        if(record.rightAnswer[questionContent.answerList.indexOf(e)] === true )
                        {
                            return (
                                <div>
                                <Form.Item style={{width:50 ,  marginTop:0, marginBottom:0}} name={'questionContent','questions' ,i, 'rightAnswer', index} valuePropName="checked">
                                    <Checkbox defaultChecked={true} 
                                        //disabled
                                        name = {"checkbox"}
                                        checked={this.state.checkbox}
                                        onChange={this.onCheckchange}
                                    ></Checkbox>
                                </Form.Item>
                                </div>
                            )
                        }
                        else 
                        {
                            return (
                                <Form.Item style={{width:50 ,  marginTop:0, marginBottom:0}} name={'questionContent','questions' ,i, 'rightAnswer'}>
                                    <Checkbox defaultChecked={false} onChange={(e)=>{this.handleAnswerOnChange(columnindex, i)}}></Checkbox>
                                    </Form.Item>
                            )
                        }
                        
                    }      
                  },

            )
        })

        columns.push({
            witdh: "50px",
            align: "right",
            render: (text, record, index)=>(
                <Button type="link" onClick={() => this.handleDelete(index)  } className="no-padding">
                            <DeleteOutlined/>
                </Button>
            )
        });

        this.setState({columns});
    }

    handleChangeInput = (e, i) => {
        const { questions } = this.state;
        const newAnswerList = {...questions};
        newAnswerList.questions[i].question = e.target.value;
        this.handleChangeQuestionContent(newAnswerList);
    }

    handleAddQuest = () => {
        const { questions } = this.state;
        let newQuestion =[]
        let newrightAnswer=[]

        if (questions.answerList)
        {
            newrightAnswer[0] = true;
            for (let i =1; i<questions.answerList.length;i++)
            {
                newrightAnswer[i] = false;
            }
        }
        else newrightAnswer=[true];
        

        if(questions.questions)
        {
            newQuestion = [...questions.questions, {
            question: "",
            rightAnswer: newrightAnswer
            }];
        }
        else {
            newQuestion =[{
                question: "",
                rightAnswer: newrightAnswer
                }];
            
        }
        this.setState({
            questions: {
                ...questions,
                questions: newQuestion
            }
        })
        this.handleChangeQuestionContent({...questions, questions: newQuestion});
        this.setState({searchvalue:""})
    }
    
    handleDelete = (i) => {
        const { questions } = this.state;
        const newQuestion = questions.questions.filter((item, index) => index !== i);
        this.setState({
            questions: {
                ...questions,
                questions: newQuestion
            }
        });
        this.handleChangeQuestionContent({...questions, questions: newQuestion});
    }

    handleAddColumn=(value)=> {
        const { questions, columns } = this.state;
        if(value===""|| columns.length>=7) return; 
        this.setState({searchvalue:""})
        this.setState({isColumnEditing:false})
        
        let newQuestion =this.state.questions;
        let answerList = [];

        if (newQuestion.answerList &&newQuestion.questions)
        {
            answerList = [...questions.answerList, value];
            newQuestion.answerList=answerList;
            newQuestion.questions.forEach(e=>{
                e.rightAnswer=[...e.rightAnswer,false]
            })
        }
        else if (newQuestion.answerList) {
            answerList = [...questions.answerList, value];
            newQuestion.answerList=answerList;            
        }
        else {
            answerList = [value];
            newQuestion.answerList=answerList;
        }
        const newColumn ={ 
            title: ()=>
            <div onClick={(e)=>this.handleRenameColumn(value,questions.answerList.indexOf(value))}>{value}</div>,
            align: "center",
            dataIndex: "rightAnswer",
            render: (question, record, i) => {         
                if(record.rightAnswer[questions.answerList.indexOf(value)] === true )
                {
                    return (
                        <div>
                        <Form.Item style={{width:50 , marginTop:0, marginBottom:0}}  
                            name={'questionContent','questions' ,i, 'rightAnswer'} 
                            onChange={(e)=>{this.handleAnswerOnChange(questions.answerList.indexOf(value), i)}}
                        > 
                            <Checkbox defaultChecked={true} 
                                // disabled 
                                name = {"checkbox"}
                                checked={this.state.checkbox}
                                onChange={this.onCheckchange}
                            
                            ></Checkbox>
                        </Form.Item>
                        </div>
                    )
                }
                else 
                {
                    return (
                        <Form.Item style={{width:50 ,  marginTop:0, marginBottom:0}} name={'questionContent','questions' ,i, 'rightAnswer'}>
                            <Checkbox defaultChecked={false} onChange={(e)=>{this.handleAnswerOnChange(questions.answerList.indexOf(value), i)}}></Checkbox>
                            </Form.Item>
                    )
                }
            }
        };

        let temp = [];
        temp.push(columns[columns.length-1]);
        columns.pop();
        this.setState({columns: [...columns, newColumn, ...temp],})
        this.handleChangeQuestionContent({...questions, answerList:answerList});
    }

    handleAnswerOnChange=(choosenColumn, choosenRow)=>
    {
        const { questions, } = this.state;        
        let newQuestion ={...questions};
        
        for (let i = 0 ;i<newQuestion.questions[choosenRow].rightAnswer.length;i++)
            newQuestion.questions[choosenRow].rightAnswer[i]  = false;
        newQuestion.questions[choosenRow].rightAnswer[choosenColumn]=true;
        this.handleChangeQuestionContent({...newQuestion});
    }
    handleRenameColumn=(value, index)=>
    {
        const {questions} = this.state;
        this.setState({isColumnEditing:true});
        this.setState({ColumnEditting: index});
        this.setState({ColumnEdittingValue:questions.answerList[index]})
        this.setState({searchvalue:questions.answerList[index]})
        return;
    }
    handleConfirmRename=(value)=>
    {
        if(value ==="") return;
        const { questions,ColumnEditting, columns } = this.state;
        const newAnswerList = {...questions};
        newAnswerList.answerList[ColumnEditting] = value;
        const newColumn ={ 
            title: ()=>
            <div onClick={(e)=>this.handleRenameColumn(value,questions.answerList.indexOf(value))}>{value}</div>,
            align: "center",
            dataIndex: "rightAnswer",
            render: (question, record, i) => {
                if(record.rightAnswer[questions.answerList.indexOf(value)] === true )
                {
                    return (
                        <div>
                        <Form.Item style={{width:50 ,  marginTop:0, marginBottom:0}}  name={'questionContent','questions' ,i, 'rightAnswer'} > 
                            <Checkbox defaultChecked={true} 
                            // disabled
                            name = {"checkbox"}
                            checked={this.state.checkbox}
                            onChange={this.onCheckchange}
                            ></Checkbox>
                        </Form.Item>
                        </div>
                    )
                }
                else 
                {
                    return (
                        <Form.Item style={{width:50 ,  marginTop:0, marginBottom:0}} name={'questionContent','questions' ,i, 'rightAnswer'}>
                            <Checkbox defaultChecked={false} onChange={(e)=>{this.handleAnswerOnChange(questions.answerList.indexOf(value), i)}}></Checkbox>
                            </Form.Item>
                    )
                }
            }
        };

        const newColumns = [...columns];
        newColumns[ColumnEditting+1] = newColumn;
        this.handleChangeQuestionContent(newAnswerList);
        this.setState({searchvalue:""})
        this.setState({columns: newColumns})
        this.setState({isColumnEditing:false});
    }

    handleChangeQuestionContent = (newQuestionContent) => {
        const { handleChangeOtherData } = this.props;
        handleChangeOtherData(newQuestionContent);
    }
    onInputchange(event) {
        this.setState({
          [event.target.name]: event.target.value
        });
      }

      onCheckchange(event) {
        this.setState({
          [event.target.name]: true
        });
      }
    render() {
        const { disabled } = this.props;
        const { questions, } = this.state;
        return (
            <Card size="small"  style={{ width: '100%', marginBottom: '4px' }}>
            <Row>
                <Col span={12}>
                    Danh sách câu hỏi
                </Col>
            </Row>
            <Row>    
                <Col span={12}>
                    <div style={{ textAlign: "left"}}>
                        <Button  type="primary" onClick={() => {this.handleAddQuest()}}
                            // required
                            rules={[
                                {
                                  required: true,
                                  message: ""
                                }
                              ]}
                        >
                        <PlusOutlined /> Thêm câu hỏi
                        </Button>
                    </div>
                </Col>

                <Col span={12} style={{display:'inline-flex', justifyContent:'flex-end'}}>
                {this.state.isColumnEditing===false ||this.state.searchvalue===""?
                <Search placeholder="Thêm đáp án" enterButton={<PlusOutlined/>} allowClear onSearch={this.handleAddColumn} style={{ width: 200 }} 
                    defaultValue={""}
                    value={this.state.searchvalue}
                    name="searchvalue"
                    onChange={this.onInputchange}
                ></Search>
                :
                <Search placeholder="Sửa đáp án" enterButton={<CheckOutlined />} allowClear onSearch={(e)=>this.handleConfirmRename(e)} style={{ width: 200 }} 
                    defaultValue={this.state.ColumnEdittingValue?this.state.ColumnEdittingValue:undefined}
                    value={this.state.searchvalue}
                    name="searchvalue"
                    onChange={this.onInputchange}
                ></Search>
                }
                </Col>

                
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item 
                        name={'questionContent'} 
                        label="Câu hỏi"
                        rules={[
                            {
                              required: true,
                              message: "Vui lòng nhập câu hỏi"
                            }
                          ]}
                        >
                            <BaseTable
                            columns={this.state.columns}
                            rowKey={(record, i) => i}                            
                            dataSource={questions.questions}
                            loading={disabled}
                            pagination={false}
                            
                            >
                                </BaseTable>
                           
                    </Form.Item>
                </Col>
            </Row>
            </Card>
        )
    }
}
