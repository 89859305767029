import React, { useState, useEffect } from 'react';
import objectAssign from 'object-assign';
import Slider from 'react-slick';
import { Upload, Modal, Spin, Select, Form } from 'antd';
import { PlusOutlined, LoadingOutlined, EditOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import RegionSelect from 'react-region-select';
import {
    AppConstants,
    UploadFileTypes,
  } from "../../../constants";
import Utils from "../../../utils";
import { showErrorMessage } from "../../../services/notifyService";
import Carousel from './Carousel';
import logo from '../../../assets/images/logo.png'

const { confirm } = Modal;

const commonRatio = [
    { value: 16/9, label: '16:9'},
    { value: 11/19, label: '11:19'},
]

function StepByStepOnImages({
    uploadFile,
    isEditing,
    handleChangeOtherData,
    questionContent,
    fieldName
}) {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [defaultQuestionContent, setDefaultQuestionContent] = useState(questionContent);
    const [images, setImages] = useState(isEditing ? questionContent.reduce((c, a)=>{
        c[a.id] = {
            ...a,
            region: {
                ...a.region,
                new: false,
                data: { index: 0 },
                isChanging: false,
            }
        }
        return c;
    }, {}) || {} : {});

    const [currentImage, setCurrentImage] = useState(isEditing ? questionContent && questionContent[0] && {
        id: questionContent[0].id,
        onLoading: true,
    } : {
        id: undefined,
        onLoading: false,
    });
    const [ratio, setRatio] = useState(commonRatio[0].value);
    const regionStyle = {
        background: "rgba(0, 255, 0, 0.5)",
        zIndex: 99
    };

    useEffect(() => {
        if(isEditing && questionContent) {
            //Ratio
            const image = new Image();
            const path = `${AppConstants.contentRootUrl}${questionContent[0].path}`;
            image.onload = function(){
                const h = image.height;
                const w = image.width;
                const ratio = w/h;
                const currentRatio = commonRatio.find(c => Math.abs(c.value - ratio) <= 0.001)?.value || commonRatio[0].value //Sai so khi chia
                setRatio(currentRatio);
            }
            image.src = path;
        }
    }, [])

    useEffect(() => {
        const newQuestionContent = Object.keys(images).map(img => {
            return {
                id: img,
                path: images[img].path,
                region: {
                    x: images[img].region?.x || 0,
                    y: images[img].region?.y || 0,
                    width: images[img].region?.width || 0,
                    height: images[img].region?.height || 0,
                }
            }
        })
        handleChangeOtherData(newQuestionContent);
    }, [images]);

    const onChangeRegion = updatedRegion => {
        const update = {};
        update[currentImage.id] = {
            id: currentImage.id,
            path: images[currentImage.id].path,
            region: {
                ...updatedRegion[0],
                new: false,
                data: { index: 0 },
                isChanging: false,
            }
        }
        setImages({
            ...images,
            ...update,
        });
    };

    const beforeUpload = (file, fileList) => {
        return Utils.beforeUploadImage(file);
    }

    const uploadFileAvatar = ({file, onSuccess}) => {
        setLoading(true);
        uploadFile({
            params: { fileObjects: { file }, type: UploadFileTypes.AVATAR },
            onCompleted: (result) => {
                setCurrentImage({
                    id: result.data?.filePath,
                    onLoading: true,
                });
                const update = {};
                update[result.data?.filePath] = {
                    id: result.data?.filePath,
                    path: result.data?.filePath,
                    region: {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                        new: false,
                        data: { index: 0 },
                        isChanging: false,
                    }
                }
                setImages({
                    ...images,
                    ...update,
                });
                onSuccess();
                setLoading(false);
                if(!showModal)
                    setShowModal(true);
            },
            onError: (err) => {
            if (err && err.message) {
                showErrorMessage(err.message);
                setLoading(false);
            }
            },
        });
    };

    const handleChangeCurrentImage = (id) => {
        setCurrentImage({
            id,
            onLoading: id !== currentImage.id,
        })
    }

    const onOk = () => {
        const newQuestionContent = Object.keys(images).map(img => {
            return {
                id: img,
                path: images[img].path,
                region: {
                    x: images[img].region?.x || 0,
                    y: images[img].region?.y || 0,
                    width: images[img].region?.width || 0,
                    height: images[img].region?.height || 0,
                }
            }
        })
        setDefaultQuestionContent(newQuestionContent);
        setShowModal(false);
    }

    const onCancel = () => {
        let defaultImages = {};
        if(isEditing) {
            defaultImages = defaultQuestionContent && defaultQuestionContent.reduce((c, a)=>{
                c[a.id] = {
                    ...a,
                    region: {
                        ...a.region,
                        new: false,
                        data: { index: 0 },
                        isChanging: false,
                    }
                }
                return c;
            }, {}) || {};
        }
        if(Object.keys(images).length > 0){
            if(JSON.stringify(defaultImages) !== JSON.stringify(images)){
                confirm({
                    title: `Tất cả những thay đổi sẽ mất, bạn có chắc chắn muốn hủy?`,
                    content: '',
                    okText: 'Có',
                    okType: 'danger',
                    cancelText: 'Không',
                    onOk: () => {
                        Promise.resolve().then(() => {
                            setCurrentImage(isEditing ? questionContent && questionContent[0] && {
                                id: questionContent[0].id,
                                onLoading: true,
                            } : {
                                id: undefined,
                                onLoading: false,
                            })
                            setImages(defaultImages);
                            setShowModal(false);
                        })
                    },
                    onCancel() {
                    // console.log('Cancel');
                    },
                });
            }
            else {
                setShowModal(false);
            }
        }
        else {
            Promise.resolve().then(() => {
                setCurrentImage({
                    id: undefined,
                    onLoading: false,
                })
                setImages({});
                setShowModal(false);
            })
        }
    }

    const handleRemoveImage = (id) => {
        let newImages = JSON.stringify(images);
        newImages = JSON.parse(newImages);
        delete newImages[id];
        if(Object.keys(newImages)?.length <= 0) {
            Promise.resolve().then(() => {
                setCurrentImage({
                    id: undefined,
                    onLoading: false,
                })
                setImages({});
            })
        }
        else {
            Promise.resolve().then(() => {
                setCurrentImage({
                    id: Object.keys(newImages)[0],
                    onLoading: true,
                })
                setImages(newImages);
            })
        }
    }

    const imageLoaded = () => {
        setCurrentImage({
            ...currentImage,
            onLoading: false,
        })
    }

    const handleChangeRatio = (value) => {
        setRatio(value);
    }

    return (
        <Form.Item
            name={fieldName}
        >
            <div className="step-by-step-on-img">
                <div className="add-img">
                    {
                        isEditing || Object.keys(images).length > 0 ? (
                            <div className="edit-img" onClick={() => { setShowModal(true)}}>
                                <EditOutlined />
                                <div className="ant-upload-text">Chỉnh sửa hình</div>
                            </div>
                        ) : (
                            <div className="edit-img add" onClick={() => { setShowModal(true)}}>
                                <PlusOutlined />
                                <div className="ant-upload-text">Thêm hình</div>
                            </div>
                        )
                    }
                </div>
                <Modal
                    destroyOnClose // rerender child component when modal close
                    style={40}
                    width={800}
                    visible={showModal}
                    onOk={onOk}
                    onCancel={onCancel}
                    className="modal-step-by-step"
                    closable={false}
                >
                    <div style={{height: 400}}>
                        <div className="region-ctn">
                            {
                                images[currentImage.id] ? (
                                    <RegionSelect
                                        maxRegions={1}
                                        regions={images[currentImage.id]?.region ? [{...images[currentImage.id]?.region}] : []}
                                        regionStyle={regionStyle}
                                        constraint
                                        onChange={onChangeRegion}
                                    >
                                        <Spin spinning={currentImage.onLoading}>
                                            <img
                                                onLoad={imageLoaded}
                                                src={`${AppConstants.contentRootUrl}${images[currentImage.id]?.path}`}
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: 400,
                                                    width: 'auto',
                                                    cursor: 'crosshair',
                                                }}
                                            />
                                        </Spin>
                                    </RegionSelect>
                                ) : null
                            }
                        </div>
                    </div>
                    <div className="ctn-upload-img">
                        <Carousel
                            images={images}
                            currentImage={currentImage.id}
                            handleChangeCurrentImage={handleChangeCurrentImage}
                            handleRemoveImage={handleRemoveImage}
                        />
                        <div>
                            <ImgCrop aspect={ratio}>
                                <Upload
                                    name="field-upload"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    beforeUpload={beforeUpload}
                                    customRequest={uploadFileAvatar}
                                    multiple={false}
                                    showUploadList={false}
                                    disabled={Object.keys(images).length >= 5}
                                >
                                    <div>
                                        {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                    </div>
                                </Upload>
                            </ImgCrop>
                        </div>
                        <div className="select-ratio">
                            <Form.Item
                                label="Tỉ lệ khung hình"
                            >
                                <Select
                                    disabled={loading}
                                    onChange={handleChangeRatio}
                                    defaultValue={ratio}
                                >
                                    {
                                        commonRatio.map(item =>
                                            <Select.Option 
                                                key={item['value']} 
                                                value={item['value']} 
                                            >
                                                {item['label']}
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </Modal>
            </div>
        </Form.Item>
    );
}

export default StepByStepOnImages;