import reduxHelper from '../utils/redux';

export const reduxUtil = reduxHelper('AREA');
const { defineAction, createActionWithLoading, createAction } = reduxUtil;

export const actionTypes = {
    GET_AREA: defineAction('GET_AREA'),
    CREATE_AREA: defineAction('CREATE_AREA'),
    DELETE_AREA: defineAction('DELETE_AREA'),
    UPDATE_AREA: defineAction('UPDATE_AREA'),
    GET_AREA_LIST: defineAction('GET_AREA_LIST'),
}

export const actions = {
    getArea: createAction(actionTypes.GET_AREA),
    createArea: createAction(actionTypes.CREATE_AREA),
    deleteArea: createActionWithLoading(actionTypes.DELETE_AREA),
    updateArea: createAction(actionTypes.UPDATE_AREA),
    getAreaList: createActionWithLoading(actionTypes.GET_AREA_LIST),
    
}