const baseHeader = {
    'Content-Type': 'application/json',
    'Accept': '*/*'
}

const multipartFormHeader = {
    'Content-Type': 'multipart/form-data'
}


const apiConfig = {
    file: {
        upload: {
            path: '/v1/file/upload',
            method: 'POST',
            headers: multipartFormHeader
        }
    },
    account: {
        login: {
            path: '/v1/account/login',
            method: 'POST',
            headers: baseHeader
        },
        logout: {
            path: '/v1/account/logout',
            method: 'GET',
            headers: baseHeader
        },
        getAdminProfile: {
            path: '/v1/account/profile',
            method: 'GET',
            headers: baseHeader
        },
        getShopAccountProfile: {
            path: '/v1/shop_account/profile',
            method: 'GET',
            headers: baseHeader
        },
        updateShopAccountProfile: {
            path: '/v1/shop_account/update_profile',
            method: 'PUT',
            headers: baseHeader
        },
        updateProfileAdmin: {
            path: '/v1/account/update_profile',
            method: 'PUT',
            headers: baseHeader
        }
    },
    user: {
        getAdminList: {
            path: '/v1/account/list',
            method: 'GET',
            headers: baseHeader
        },
       
        getAdminById: {
            path: '/v1/account/get',
            method: 'GET',
            headers: baseHeader
        },
        createAdmin: {
            path: '/v1/account/create_admin',
            method: 'POST',
            headers: baseHeader
        },
        updateAdmin: {
            path: '/v1/account/update_admin',
            method: 'PUT',
            headers: baseHeader
        },
        deleteAdmin: {
            path: '/v1/account/delete',
            method: 'DELETE',
            headers: baseHeader
        },
    },
    groupPermission: {
        getList: {
            path: '/v1/group/list',
            method: 'GET',
            headers: baseHeader
        },
        getPermissionList: {
            path: '/v1/permission/list',
            method: 'GET',
            headers: baseHeader
        },
        getById: {
            path: '/v1/group/get',
            method: 'GET',
            headers: baseHeader
        },
        create: {
            path: '/v1/group/create',
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: '/v1/group/update',
            method: 'PUT',
            headers: baseHeader
        },
        updateStatus: {
            path: '/v1/skills/status',
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: '/v1/skills',
            method: 'DELETE',
            headers: baseHeader
        },
    },
   
    setting: {
        getSettingsList: {
            path: '/v1/settings/list',
            method: 'GET',
            headers: baseHeader
        },
        createSetting: {
            path: '/v1/settings/create',
            method: 'POST',
            headers: baseHeader
        },
        getSettingById: {
            path: '/v1/settings/get',
            method: 'GET',
            headers: baseHeader
        },
        updateSetting: {
            path: '/v1/settings/update',
            method: 'PUT',
            headers: baseHeader
        },
        deleteSetting: {
            path: '/v1/settings/delete',
            method: 'DELETE',
            headers: baseHeader
        }
    },
    province: {
        getProvinceList: {
            path: '/v1/province/list',
            method: 'GET',
            headers: baseHeader
        },
        createProvince: {
            path: '/v1/province/create',
            method: 'POST',
            headers: baseHeader
        },
        getProvinceById: {
            path: '/v1/province/get',
            method: 'GET',
            headers: baseHeader
        },
        updateProvince: {
            path: '/v1/province/update',
            method: 'PUT',
            headers: baseHeader
        },
        deleteProvince: {
            path: '/v1/province/delete',
            method: 'DELETE',
            headers: baseHeader
        },
        getProvinceCombobox: {
            path: '/v1/province/list_combobox',
            method: 'GET',
            headers: baseHeader
        }
    },
    category: {
        getCategoryList: {
            path: '/v1/category/list',
            method: 'GET',
            headers: baseHeader
        },
        getById: {
            path: '/v1/category/get',
            method: 'GET',
            headers: baseHeader
        },
        create: {
            path: '/v1/category/create',
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: '/v1/category/update',
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: '/v1/category/delete',
            method: 'DELETE',
            headers: baseHeader
        },
    },
    news: {
        getNewsList: {
            path: '/v1/news/list',
            method: 'GET',
            headers: baseHeader
        },
        getNewsById: {
            path: '/v1/news/get',
            method: 'GET',
            headers: baseHeader
        },
        createNews: {
            path: '/v1/news/create',
            method: 'POST',
            headers: baseHeader
        },
        updateNews: {
            path: '/v1/news/update',
            method: 'PUT',
            headers: baseHeader
        },
        deleteNews: {
            path: '/v1/news/delete',
            method: 'DELETE',
            headers: baseHeader
        },
        getComboboxNewsList: {
            path: '/v1/category/list_combobox',
            method: 'GET',
            headers: baseHeader
        },
    },
    customer: {
        getCustomerList: {
            path: '/v1/customer/list',
            method: 'GET',
            headers: baseHeader
        },
        getCustomerById: {
            path: '/v1/customer/get',
            method: 'GET',
            headers: baseHeader
        },
        updateCustomer: {
            path: '/v1/customer/update',
            method: 'PUT',
            headers: baseHeader
        },
        deleteCustomer: {
            path: '/v1/customer/delete',
            method: 'DELETE',
            headers: baseHeader
        },
    },
    surveyProfile: {
        getSurveyProfileList: {
            path: '/v1/survey_profile/list',
            method: 'GET',
            headers: baseHeader
        },
        getSurveyProfileById: {
            path: '/v1/survey_profile/get',
            method: 'GET',
            headers: baseHeader
        },
        createSurveyProfile: {
            path: '/v1/survey_profile/create',
            method: 'POST',
            headers: baseHeader
        },
        updateSurveyProfile: {
            path: '/v1/survey_profile/update',
            method: 'PUT',
            headers: baseHeader
        },
        deleteSurveyProfile: {
            path: '/v1/survey_profile/delete',
            method: 'DELETE',
            headers: baseHeader
        },
        getListAutoComplete: {
            path: '/v1/survey_profile/list-auto-complete',
            method: 'GET',
            headers: baseHeader
        }
    },
    area: {
        getAreaList: {
            path: '/v1/work_field/list',
            method: 'GET',
            headers: baseHeader
        },
        getById: {
            path: '/v1/work_field/get',
            method: 'GET',
            headers: baseHeader
        },
        create: {
            path: '/v1/work_field/create',
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: '/v1/work_field/update',
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: '/v1/work_field/delete',
            method: 'DELETE',
            headers: baseHeader
        },
        getListAutoComplete: {
            path: '/v1/work_field/list-auto-complete',
            method: 'GET',
            headers: baseHeader
        }
    },
    competences: {
        getCompetencesList: {
            path: '/v1/competences/list',
            method: 'GET',
            headers: baseHeader
        },
        getById: {
            path: '/v1/competences/get',
            method: 'GET',
            headers: baseHeader
        },
        create: {
            path: '/v1/competences/create',
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: '/v1/competences/update',
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: '/v1/competences/delete',
            method: 'DELETE',
            headers: baseHeader
        },
    },
    question: {
        getQuestionList: {
            path: '/v1/question/list',
            method: 'GET',
            headers: baseHeader
        },
        getById: {
            path: '/v1/question/get',
            method: 'GET',
            headers: baseHeader
        },
        create: {
            path: '/v1/question/create',
            method: 'POST',
            headers: baseHeader
        },
        update: {
            path: '/v1/question/update',
            method: 'PUT',
            headers: baseHeader
        },
        delete: {
            path: '/v1/question/delete',
            method: 'DELETE',
            headers: baseHeader
        },
        getComboboxCompetences: {
            path: '/v1/competences/list-auto-complete',
            method: 'GET',
            headers: baseHeader
        },
    },
    exam:{
        getDetailsAdminList:{
            path: '/v1/exam/details-admin',
            method: 'GET',
            headers: baseHeader
        },
        getListAdminList:{
            path: '/v1/exam/list-admin',
            method: 'GET',
            headers: baseHeader
        }
    }
}
export default apiConfig;
